import GoogleAnalytics, { EventArgs } from 'react-ga';

export function isInDevMode(): boolean {
  return (
    process.env.NODE_ENV === 'development' ||
    window.location.host !== 'lokumkum.pl'
  );
}

export function initializeAnalytics(googleTrackingCode: string): void {
  if (isInDevMode()) {
    return;
  }
  GoogleAnalytics.initialize(googleTrackingCode);
  GoogleAnalytics.pageview(window.location.pathname + window.location.search);
}

export function makeHandler(
  payload: EventArgs
): (overrides?: Partial<EventArgs>) => void {
  if (isInDevMode()) {
    return () => undefined;
  }
  return (overrides?: Partial<EventArgs>) => {
    GoogleAnalytics.event({ ...payload, ...overrides });
  };
}
