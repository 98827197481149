import React from 'react';
import { useCompanyDetails } from 'public/providers/company-details-provider';
import { PageContainer } from 'public/components/page-container';
import { Navbar } from 'app/shared/components/navbar';
import { Container } from '@material-ui/core';

export function TermsOfService() {
  const {
    serviceName,
    companyName,
    address: addressArray,
    nip,
    regon,
    deploymentAddress,
    termsOfServiceUrl,
    contactEmail
  } = useCompanyDetails();
  const address = addressArray.join(', ');
  return (
    <PageContainer navbar={<Navbar />}>
      <Container>
        <h1 className={'text-center'}>Regulamin serwisu {serviceName}</h1>
        <ol>
          <h2>
            <li className={'text-center'}>Definicje</li>
          </h2>
          <h3>Użyte w niniejszym Regulaminie pojęcia oznaczają:</h3>
          <ol>
            <li>
              <strong>{serviceName} </strong> – {companyName} z siedzibą pod
              adresem: {address}, NIP: {nip}, REGON: {regon}.
            </li>
            <li>
              <strong>Serwis</strong> – serwis internetowy dostępny pod adresem:{' '}
              {deploymentAddress}.
            </li>
            <li>
              <strong>Użytkownik</strong> – jakikolwiek podmiot korzystający z
              Serwisu, a w szczególności zawierająca umowę z Serwisem.
            </li>
            <li>
              <strong>Konsument</strong> – Użytkownik będący osobą fizyczną,
              zawierający z Serwisem umowę niezwiązaną bezpośrednio z jego
              działalnością gospodarczą lub zawodową.
            </li>
            <li>
              <strong>Konto</strong> – konto użytkownika w Serwisie.
            </li>
            <li>
              <strong>Regulamin</strong> – niniejszy regulamin Serwisu dostępny
              na stronie {termsOfServiceUrl}
            </li>
            <li>
              <strong>Subskrypcja</strong> – umowa dotycząca świadczenia Usługi.
            </li>
            <li>
              <strong>Umowa Główna</strong> – umowa dotycząca założenia Konta,
              umożliwiająca w szczególności dostęp do zawarcia Subskrypcji,
            </li>
            <li>
              <strong>Usługa</strong> – świadczenie dokonywane przez Serwis na
              rzecz Użytkownika,
            </li>
            <li>
              <strong>Usługa Premium</strong> – usługa pozwalająca a korzystanie
              z większej liczby funkcjonalności Serwisu-Usługa świadczona na
              rzecz Użytkownika, który wykupił Subskrypcje w okresie ważności
              Subskrypcji.
            </li>
            <li>
              <strong>Użytkownik</strong> – osoba korzystająca z Serwisu,
              zarówno posiadająca Konto, jak i nieposiadająca, ale przeglądająca
              Serwis
            </li>
            <li>
              <strong>Ustawa o prawach konsumenta</strong> – Ustawa o prawach
              konsumenta z dnia 30 maja 2014 r. (Dz.U. z 2014 r. poz. 827).
            </li>
            <li>
              <strong>Ustawa o świadczeniu usług drogą elektroniczną</strong>–
              Ustawa z dnia 18 lipca 2002 roku o świadczeniu usług drogą
              elektroniczną (Dz. U. z 2002 roku, Nr 144, poz. 1204 ze zm.).
            </li>
            <li>
              <strong>Kodeks Cywilny</strong> – Ustawa z dnia 23 kwietnia 1964
              roku – Kodeks cywilny (Dz.U. z 1964 roku, nr 16, poz. 93 z późn.
              zm.).
            </li>
          </ol>
          <h2>
            <li className={'text-center'}>Postanowienia ogólne</li>
          </h2>
          <ol>
            <li>
              Regulamin jest regulaminem, o którym mowa w art. 8. Ustawy o
              świadczeniu usług drogą elektroniczną.
            </li>
            <li>
              {serviceName} świadczy drogą elektroniczną za pośrednictwem
              Serwisu Usługi polegające na dostępie do wyszukiwarki
              zagregowanych ogłoszeń dotyczących w szczególności nieruchomości
              oraz innych danych zawartych w Serwisie, w tym Usługi Premium
              (dające w szczególności możliwość do dostępu do informacji
              wszystkich informacji dostępnych w Serwisie, wcześniej niż
              niektóre z nich dostępne są one dla Użytkowników, którzy nie
              wykupili Subskrypcji).
            </li>
            <li>
              Dane kontaktowe, pod którymi Użytkownik szybko i efektywnie mogą
              kontaktować się z {serviceName} to:
              <ol>
                <li>adres poczty elektronicznej: {contactEmail},</li>
                <li>adres poczty tradycyjnej: {address}.</li>
              </ol>
            </li>
            <li>
              {serviceName} kontaktuje się z Użytkownikami za pośrednictwem
              Serwisu lub poczty elektronicznej.
            </li>
            <li>
              Zakazane jest dostarczanie treści o charakterze bezprawnym w
              ramach korzystania z Serwisu przez Użytkowników.
            </li>
          </ol>
          <h2>
            <li className={'text-center'}>Wymagania techniczne</li>
          </h2>
          <ol>
            <li>
              Dla prawidłowej współpracy z systemem teleinformatycznym{' '}
              {serviceName}, system teleinformatyczny Użytkownika powinien
              spełniać następujące minimalne wymagania techniczne:
            </li>
            <ul>
              <li>zapewniać dostęp do sieci Internet,</li>
              <li>
                posiadać zainstalowaną przeglądarkę internetową (np. Google
                Chrome, Mozilla Firefox, Safari) w najnowszej stabilnej wersji
                dostarczanej przez producenta,
              </li>
              <li>
                przeglądarka internetowa musi mieć uruchomioną obsługę Java
                Script i Cookies.
              </li>
            </ul>
            <li>
              Każda umowa o świadczenie usług drogą elektroniczną zawarta na
              podstawie Regulaminu może zostać rozwiązana na zasadach
              określonych w obowiązujących przepisach prawa, jak również – w
              zakresie, w jakim jest to dopuszczalne przez obowiązujące przepisy
              – na zasadach określonych w Regulaminie.
            </li>
            <li>
              Celem uczynienia zadość wymogom Ustawy o świadczeniu usług drogą
              elektroniczną, {serviceName} niniejszym informuje, że:
            </li>
            <ol>
              <li>
                korzystanie z usług świadczonych drogą elektroniczną za
                pośrednictwem sieci Internet wiąże się z ryzykiem. Podstawowym
                zagrożeniem jest możliwość zainfekowania systemu
                teleinformatycznego przez tzw. wirusy, robaki i konie
                trojańskie. By uniknąć zagrożeń z tym związanych zaleca się, by
                osoby korzystające z Serwisu zainstalowały na swoim urządzeniu
                oprogramowanie antywirusowe i na bieżąco je aktualizowały.
              </li>
              <li>
                {serviceName} wprowadza do systemu informatycznego, którym
                posługuje się osoba korzystająca Serwisu pliki cookies, tj. mały
                fragment tekstu, który serwis internetowy wysyła do przeglądarki
                i który przeglądarka wysyła z powrotem przy następnych wejściach
                na witrynę. Używane jest głównie do utrzymywania sesji np.
                poprzez wygenerowanie i odesłanie tymczasowego identyfikatora po
                logowaniu. Może być jednak wykorzystywane szerzej poprzez
                zapamiętanie dowolnych danych, które można zakodować jako ciąg
                znaków. Dzięki temu użytkownik nie musi wpisywać tych samych
                informacji za każdym razem, gdy powróci na tę stronę lub
                przejdzie z jednej strony na inną.
              </li>
            </ol>
            <li>
              Spełnienie świadczenia niezamówionego przez Konsumenta następuje
              na ryzyko {serviceName} i nie nakłada na Konsumenta żadnych
              zobowiązań z tego tytułu. Brak odpowiedzi Konsumenta na
              niezamówione świadczenie nie stanowi zgody Konsumenta na zawarcie
              jakiejkolwiek umowy.
            </li>
            <li>
              {serviceName} uprawniony jest do przesyłania na adres poczty
              elektronicznej (e-mail) Użytkownika informacji handlowych, zgodnie
              z przepisami Ustawy o świadczeniu usług drogą elektroniczną. Może
              mieć to jedynie miejsce po uprzednim wyrażeniu przez Użytkownika
              wyraźnej zgody poprzez kliknięcie pola wyboru (tzw. checkboxa).
            </li>
            <li>
              Korzystanie z Serwisu oznacza akceptację Regulaminu. Zawarcie umów
              poprzez Serwis (w szczególności Umowy Głównej oraz Subskrypcji)
              jest możliwe po potwierdzeniu zapoznania się i akceptacji
              Regulaminu poprzez kliknięcie przez Użytkownika odpowiedniego pola
              wyboru (tzw. checkboxa).
            </li>
            <li>
              Wszystkie osoby korzystające z Serwisu mogą uzyskać dostęp do
              Regulaminu w każdym czasie poprzez przejście do zakładki:
              „Regulamin” widocznej na stronie głównej Serwisu –
              {termsOfServiceUrl}. Regulamin również może zostać pobrany w
              formie pliku .pdf oraz wydrukowany.
            </li>
          </ol>
          <h2>
            <li className={'text-center'}>Konto, zawarcie Umowy Głównej</li>
          </h2>
          <ol>
            <li>Dostęp do Serwisu możliwy jest po założenia Konta.</li>
            <li>
              Tworząc Konto, Użytkownik ma obowiązek podać prawdziwe dane.
              Podanie nieprawdziwych danych stanowi naruszenie Regulaminu.
            </li>
            <li>Aby zarejestrować Konto, Użytkownik musi:</li>
            <ol>
              <li>
                wypełnić formularz udostępniony w Serwisie podając adres e-mail
                oraz tworząc i potwierdzając hasło,
              </li>
              <li>
                potwierdzić, że zapoznał się i wyraża zgodę na treść Regulaminu.
              </li>
            </ol>
            <li>
              Umowa Główna zawarta jest z chwilą zakończenia rejestracji Konta.
              Umowa Główna zawarta jest na czas nieoznaczony.
            </li>
            <li>
              Z zawarciem Umowy Głównej nie wiążą się żadne obowiązki płatności.
            </li>
          </ol>
          <h2>
            <li className={'text-center'}>Subskrypcja</li>
          </h2>
          <ol>
            <li>
              Po założeniu Konta, Użytkownik może w Serwisie wykupić
              Subskrypcję, tj. zawrzeć umowę na podstawie której {serviceName}
              świadczyć będzie na rzecz Użytkownika Usługi.
            </li>
            <li>
              Przedmiotem usługi objętej Subskrypcją jest udostępnienie
              oprogramowania i miejsca w bazie danych w celu monitorowania
              podanych przez Użytkownika adresów URL w zakresie udostępnianych
              tam informacji o nieruchomościach. Celem oferowanej usługi jest
              optymalizacja czasu potrzebnego na obserwację zmian na rynku
              nieruchomości. Czynność monitorowania przeprowadzana jest w
              imieniu Użytkownika. Jeśli przetwarzanie informacji dostępnych pod
              URL podanym przez użytkownika wymaga akceptacji regulaminu tego
              serwisu, regulamin ten wiąże tylko i wyłącznie użytkownika, jako
              że oprogramowanie przeprowadza czynność zbierania danych w jego
              imieniu. Pozyskane dane o nieruchomościach przechowywane są w
              udostępnionej przestrzeni bazy danych i oznaczone danymi
              Użytkownika, na zlecenie którego zostały pobrane. Użytkownik w
              ramach usługi zobowiązuje się podzielić danymi uzyskanymi przez
              udostępnione oprogramowanie z {companyName} w zakresie dozwolonym
              przez prawo, w celu optymalizacji procesu obserwacji rynku
              nieruchomości. Jednocześnie Subskrypcja w ramach optymalizacji
              procesu zbierania danych o nieruchomościach daje Użytkownikowi
              dostęp do danych zebranych wcześniej przez innych użytkowników i
              udostępnionych {serviceName} jeśli pokrywają się z danymi
              monitorowania podanych przez Użytkownika adresów URL.
            </li>
            <li>Zawarcie Subskrypcji dokonywane jest poprzez:</li>
            <ol>
              <li>Zgłoszenie chęci zawarcia Subskrypcji do {companyName}</li>
              <li>Akceptację Regulaminu,</li>
              <li>Uiszczenie płatności za Subskrypcję,</li>
              <li>
                przesłanie przez {serviceName} wiadomości e-mail Użytkownikowi z
                potwierdzeniem zawarcia Subskrypcji, tj. przyjęcia przez{' '}
                {serviceName}
                oferty zawarcia Subskrypcji przesłanej przez Użytkownika.
              </li>
            </ol>
            <li>
              Subskrypcja wykupywana jest na czas miesiąc i może być
              automatycznie przedłużona na kolejny miesiąc po jej wygaśnięciu,
              jeśli wybrany sposób płatności pozwala na automatycznie pobranie
              opłaty za kolejny okres
            </li>
            <li>
              Usługi dostępne są, albo po 14 dniach od zawarcia umowy dotyczącej
              Subskrypcji, albo po potwierdzeniu przez Użytkownika chęci tego,
              że Usługi Premium zostaną uruchomione przed tym terminem, z
              zastrzeżeniem że oznacza to utratę prawa do odstąpienia od
              Subskrypcji w zakresie w jakim Usługi Premium zostały wykonane.
            </li>
            <li>
              {serviceName} wyśle Użytkownikowi e-mail potwierdzający wykupienie
              Subskrypcji (zawarcie umowy)
            </li>
            <li>
              Osoba zawierającą Umowę Główną lub Subskrypcję w imieniu osoby
              prawnej bądź jednostki niebędąca osobą prawną, której ustawa
              przyznaje zdolność prawną, a także w imieniu dowolnej osoby
              trzeciej (takiej jak np. pracodawca) poprzez dokonanie rezerwacji
              oświadcza, że ma należyte umocowanie do reprezentowania tej
              jednostki lub osoby.
            </li>
            <li>
              Użytkownik nie będący Konsumentem wyraża zgodę na wystawienie
              elektronicznej faktury bez konieczności składania swojego podpisu
              oraz na jej doręczenie na adres e-mail podany przy założeniu
              Konta.
            </li>
          </ol>
          <h2>
            <li className={'text-center'}>Prawo odstąpienia</li>
          </h2>
          <ol>
            <li>
              Konsument nabywający Subskrypcję ma możliwość odstąpienia od
              zawartej z {serviceName} umowy w terminie 14 (czternastu) dni od
              dnia zwarcia Subskrypcji, bez ponoszenia kosztów.
            </li>
            <li>
              Prawo odstąpienia, o którym mowa w punkcie 6.1, może zostać
              wykonane poprzez złożenie stosownego oświadczenia na piśmie, przy
              czym do zachowania terminu wskazanego w zdaniu poprzednim
              wystarczające jest wysłanie oświadczenia przed jego upływem na
              adres: {address} lub elektronicznie na adres email {contactEmail}.
            </li>
            <li>
              W przypadku zrealizowania prawa odstąpienia {serviceName}
              niezwłocznie zwróci Konsumentowi wszelkie dokonane płatności, przy
              użyciu takiego samego sposobu zapłaty, jakiego użył Konsument,
              chyba że Konsument wyraził zgodę na inny sposób zwrotu, który nie
              wiąże się dla niego z żadnymi kosztami.
            </li>
            <li>
              Prawo odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa
              lub na odległość nie przysługuje konsumentowi w odniesieniu do
              umów:
            </li>
            <ol>
              <li>
                o świadczenie usług, jeżeli przedsiębiorca wykonał w pełni
                usługę za wyraźną zgodą konsumenta, który został poinformowany
                przed rozpoczęciem świadczenia, że po spełnieniu świadczenia
                przez przedsiębiorcę utraci prawo odstąpienia od umowy.
              </li>
              <li>
                o dostarczanie treści cyfrowych, które nie są zapisane na
                nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło
                się za wyraźną zgodą konsumenta przed upływem terminu do
                odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę
                o utracie prawa odstąpienia od umowy.
              </li>
            </ol>
            <li>
              W przypadku zrealizowania prawa odstąpienia, {serviceName}
              zwróci Konsumentowi dokonane płatności (z zastrzeżeniem pkt 6.6) w
              terminie 14 dni od otrzymania oświadczenia Konsumenta o
              odstąpieniu od umowy, przy użyciu takiego samego sposobu zapłaty,
              jakiego użył Konsument, chyba że Konsument wyraził zgodę na inny
              sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.
            </li>
            <li>
              O ile Konsument zgodził się na rozpoczęcie wykonywania Usług przed
              upływem 14 dni od dnia wykupienia Subskrypcji (zawarcia umowy w
              tym zakresie) oraz został poinformowany o tym, że w wyniku tego
              utraci prawo do odstąpienia, wynagrodzenie
              {serviceName} odpowiadające usłudze wykonanej nie podlega
              zwrotowi.
            </li>
          </ol>
          <h2>
            <li className={'text-center'}>Tryb postępowania reklamacyjnego</li>
          </h2>
          <ol>
            <li>
              {serviceName} zobowiązuje się do wykonywania Usług w sposób
              prawidłowy.
            </li>
            <li>
              Użytkownicy mogą zgłaszać ewentualne nieprawidłowości lub
              reklamacje związane z którąkolwiek z usług świadczonych przez
              {serviceName}
              na adres: {address}, a także pocztą elektroniczną, na adres:{' '}
              {contactEmail}
            </li>
            <li>
              Reklamacja powinna zawierać:
              <ol>
                <li>
                  oznaczenie osoby reklamującej, ze wskazaniem niezbędnych
                  danych identyfikujących, w tym adresu mailowego (nie należy
                  przesyłać hasła);
                </li>
                <li>
                  oznaczenie umowy, której dotyczy reklamacja i wyczerpujące
                  wskazanie na czym polega nieprawidłowość.
                </li>
              </ol>
            </li>
            <li>
              W przypadku braku wymaganych informacji, {serviceName}
              zwróci się do reklamującego o ich uzupełnienie.
            </li>
            <li>
              W trakcie trwania procesu reklamacyjnego, {serviceName}
              może zwrócić się o dodatkowe wyjaśnienia, dokumenty lub
              weryfikację przebiegu zdarzenia podlegającego reklamacji.
            </li>
            <li>
              Reklamacje będą rozpoznawane w terminie 14 dni roboczych od
              otrzymania przez {serviceName} reklamacji zawierającej wszystkie
              niezbędne dane. O rozpatrzeniu reklamacji {serviceName}
              niezwłocznie poinformuje reklamującego pocztą elektroniczną.
            </li>
            <li>
              {serviceName} dołoży starań, by reklamacje były rozpatrywane bez
              zbędnej zwłoki.
            </li>
            <li>
              Po wyczerpaniu procedury uregulowanej w niniejszym punkcie,
              Użytkownik będący Konsumentem ma możliwość skorzystania z drogi
              sądowej w celu dochodzenia swoich ewentualnych roszczeń lub
              skorzystania z dopuszczonych przez prawo pozasądowych sposobów
              reklamacji, w szczególności mediacji i stałego sądu polubownego
              prowadzonego przez właściwe jednostki Inspekcji Handlowej, a także
              uzyskania pomocy w tym zakresie ze strony właściwego powiatowego
              lub miejskiego rzecznika praw konsumentów (przy czym
              {serviceName} nie ma obowiązku wyrażenia zgody na zastosowanie
              pozasądowych sposobów rozstrzygania reklamacji). Dokładne
              informacje na temat możliwości zastosowania procedur pozasądowych
              mogą być dostępne są m.in. w siedzibach właściwych organów
              (miejskich i powiatowych rzeczników praw konsumenta, Wojewódzkich
              Inspektoratów Inspekcji Handlowej) oraz na ich stronach
              internetowych, a także na stronach internetowych Urzędu Ochrony
              Konkurencji i Konsumentów (www.uokik.gov.pl).
            </li>
            <li>{serviceName} nie oferuje żadnych usług posprzedażnych.</li>
          </ol>
          <h2>
            <li className={'text-center'}>Dane osobowe</li>
          </h2>
          <ol>
            <li>
              {serviceName} przywiązuje szczególną wagę do poszanowania
              prywatności Użytkowników. Polityka prywatności, zgodnie z którą
              przetwarzane są dane osobowe Użytkowników dostępna jest pod
              linkiem: [polityka prywatności].
            </li>
            <li>
              Administratorem danych osobowych zbieranych poprzez Serwis jest
              {companyName}, adres: {address}, REGON: {regon}.
            </li>
          </ol>
          <h2>
            <li className={'text-center'}>Nota prawna</li>
          </h2>
          <ol>
            <li>Serwis został przygotowany na zlecenie {companyName}.</li>
            <li>
              Zawartość i struktura Serwisu chronione są prawami autorskimi.
              Powielanie danych lub informacji, w szczególności wykorzystywanie
              umieszczonych na stronie materiałów i tekstów (w tym również
              Regulaminu), jak również ich fragmentów oraz zdjęć lub filmów w
              celach innych niż wyraźnie dopuszczone przez ustawę bez zgody{' '}
              {companyName}
              jest zabronione.
            </li>
          </ol>
          <h2>
            <li className={'text-center'}>Postanowienia końcowe</li>
          </h2>
          <ol>
            <li>
              Rozstrzyganie ewentualnych sporów powstałych pomiędzy
              {serviceName}, a Użytkownikiem, który nie jest Konsumentem,
              zostaje poddane sądowi właściwemu ze względu na adres wskazany w
              pkt. 1.1. Regulaminu.
            </li>
            <li>Regulamin wchodzi w życie w dniu 11 października 2021 roku.</li>
          </ol>
        </ol>
      </Container>
    </PageContainer>
  );
}
