import React from 'react';
import { useCompanyDetails } from 'public/providers/company-details-provider';
import { PageContainer } from 'public/components/page-container';
import { Navbar } from 'app/shared/components/navbar';
import { Container } from '@material-ui/core';
import { Link } from 'public/routing/components/link';
import { TERMS_OF_SERVICE } from 'app/root/models/urls';

export function PrivacyPolicy() {
  const {
    companyName,
    address: addressArray,
    nip,
    regon,
    deploymentAddress,
    contactEmail
  } = useCompanyDetails();
  const address = addressArray.join(', ');
  return (
    <PageContainer navbar={<Navbar />}>
      <Container>
        <h1>POLITYKA PRYWATNOŚCI</h1>
        <h2>Kim jesteśmy i jak nas znaleźć</h2>
        <p>
          Twoja prywatność jest dla nas bardzo ważna, ponieważ przekazujesz nam
          swoje dane w zaufaniu, a usługi, które oferujemy, opierają się na
          nich. Ten dokument tłumaczy jakie informacje są pobierane przez nasz
          serwis {deploymentAddress} (dalej: „<strong>Serwis</strong>
          ”) – jak je zabezpieczamy oraz jak ich używamy.
        </p>
        <p>
          Nazywamy się <strong>{companyName}</strong> i jesteśmy administratorem
          Twoich danych osobowych (NIP:
          {nip}, REGON: {regon}). Nasze biuro znajduje się pod adresem {address}
          . Możesz skontaktować się z nami również za pośrednictwem e-mail:{' '}
          {contactEmail}.
        </p>
        <h2>
          Skrócona wersja – najważniejsze informacje o przetwarzaniu danych
          osobowych w Serwisie
        </h2>
        <p>
          Chcemy oprócz dbania o Twoją prywatność, zadbać również o Twój czas.
          Dlatego przygotowaliśmy dla Ciebie skróconą wersję najważniejszych
          zasad związanych z ochroną prywatności:
        </p>
        <p>
          - korzystamy z narzędzi analitycznych, takich jak Google Analytics,
          które zbierają anonimowe informacje na temat Twoich odwiedzin strony,
          takie jak podstrony, które wyświetliłeś, czas, jaki spędziłeś na
          stronie czy przejścia pomiędzy poszczególnymi podstronami. Wiąże się
          to z wykorzystaniem plików cookies firmy Google,
        </p>
        <p>
          - wykorzystujemy pliki cookies, byś mógł w sposób komfortowy korzystać
          ze strony,
        </p>
        <p>
          - wypełniając formularze w kontaktowe zamieszczone w Serwisie lub po
          prostu kontaktując się z nami bezpośrednio poprzez email, telefon,
          nasze profile na portalach społecznościowych jak również aplikacje
          mobilne tych stron, przekazujesz nam swoje dane osobowe, a my
          zobowiązujemy się, że Twoje dane pozostaną poufne, bezpieczne i nie
          zostaną udostępnione jakimkolwiek podmiotom trzecim bez Twojej
          wyraźnej zgody.
        </p>
        <p>
          Jeżeli powyższe informacje nie są dla Ciebie wystarczające, poniżej
          znajdziesz dalej idące szczegóły.
        </p>
        <h2>Jak i dlaczego przetwarzamy Twoje dane osobowe</h2>
        <p>
          <strong>
            Twoje dane osobowe przetwarzamy w celu wykonania łączącej nas z Tobą
            umowy o świadczenie usług drogą elektroniczną w zakresie dostępu do
            funkcjonalności Serwisu tj. w związku z korzystaniem przez Ciebie z
            naszego Serwisu.
          </strong>
          Jeśli chcesz dowiedzieć się więcej o jego funkcjonowaniu koniecznie
          zajrzyj do <Link to={TERMS_OF_SERVICE}>Regulaminu</Link>.
        </p>
        <p>
          Podstawą prawną przetwarzania Twoich danych osobowych jest więc art. 6
          ust. 1 lit. b) RODO (przetwarzanie jest niezbędne do wykonania umowy,
          której stroną jest osoba, której dane dotyczą, lub do podjęcia działań
          na żądanie osoby, której dane dotyczą, przed zawarciem umowy), oraz
          art. 6. ust. 1 lit. c) RODO (przetwarzanie jest niezbędne do
          wypełnienia obowiązku prawnego ciążącego na administratorze).
        </p>
        <p>
          Ponadto, podstawą prawną przetwarzania przez nas Twoich danych
          osobowych jest art. 6 ust. 1 lit. f) RODO (
          <em>
            przetwarzanie jest niezbędne do celów wynikających z prawnie
            uzasadnionych interesów realizowanych przez administratora lub przez
            stronę trzecią, z wyjątkiem sytuacji, w których nadrzędny charakter
            wobec tych interesów mają interesy lub podstawowe prawa i wolności
            osoby, której dane dotyczą, wymagające ochrony danych osobowych, w
            szczególności gdy osoba, której dane dotyczą, jest dzieckiem
          </em>
          ). Tym prawnie uzasadnionym interesem jest zapewnienie sobie przez nas
          możliwości udowodnienia w razie sporu z Tobą treści łączącej nas umowy
          oraz tego, że wykonaliśmy ją należycie.
        </p>
        <p>
          Po uzyskaniu odrębnej zgody możemy przetwarzać Twoje dane osobowe
          również w celach marketingowych (tj. do mailowego zapraszania Cię do
          skorzystania z organizowanych promocji i przesyłania Ci innych
          informacji marketingowych). Podstawą prawną przetwarzania Twoich
          danych osobowych w tych celach jest więc art. 6 ust. 1 lit. a) RODO (
          <em>
            osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich
            danych osobowych w jednym lub większej liczbie określonych celów
          </em>
          ).
        </p>
        <p>
          <strong>
            Przetwarzamy dane każdego użytkownika Serwisu charakteryzujące
            sposób korzystania przez niego z naszego Serwisu (są to tak zwane
            dane eksploatacyjne).
          </strong>
        </p>
        <p>
          Przetwarzanie to obejmuje automatyczny odczyt unikalnego oznaczenia
          identyfikującego zakończenie sieci telekomunikacyjnej lub system
          teleinformatyczny, z którego korzystasz (czyli Twojego adresu IP), a
          także datę i czas serwera, informacje o parametrach technicznych
          oprogramowania i urządzenia z którego korzystasz (np. czy przeglądając
          naszą stronę korzystasz z laptopa czy z telefonu), jak również miejsce
          z którego łączysz się z naszym serwerem. Informacje te mogą być przez
          nas wykorzystane do celów badania rynku oraz do poprawy działania
          strony. Dane zapisane w logach serwera nie są kojarzone z konkretnymi
          osobami korzystającymi z Serwisu. Logi serwera stanowią wyłącznie
          materiał pomocniczy służący do administrowania stroną.
        </p>
        <p>
          Podstawą prawną do operacji przetwarzania danych eksploatacyjnych jest
          art. 6 ust. 1 lit. f) RODO (
          <em>
            przetwarzanie jest niezbędne do celów wynikających z prawnie
            uzasadnionych interesów realizowanych przez administratora
          </em>
          ). Tym prawnie uzasadnionym interesem jest umożliwienie diagnostyki
          błędów w Serwisie i poprawa jego jakości.
        </p>
        <p>
          <strong>
            Jak niemal wszystkie inne serwisy, wykorzystujemy pliki cookies
          </strong>
          . Cookies to niewielkie informacje tekstowe, przechowywane na Twoim
          urządzeniu końcowym (np. komputerze, tablecie, smartfonie), które mogą
          być odczytywane przez nasz system teleinformatyczny.
        </p>
        <p>Cookies pozwalają nam:</p>
        <p>- zapewnić prawidłowe funkcjonowanie Serwisu,</p>
        <p>- poprawić szybkość i bezpieczeństwo korzystania z Serwisu,</p>
        <p>- korzystać z narzędzi analitycznych.</p>
        <p>
          Tutaj również podstawą prawną naszego działania jest art. 6 ust. 1
          lit. f) RODO (
          <em>
            przetwarzanie jest niezbędne do celów wynikających z prawnie
            uzasadnionych interesów realizowanych przez administratora
          </em>
          ). Tym prawnie uzasadnionym interesem jest możliwość zapewnienia
          prawidłowego funkcjonowania Serwisu, jego diagnostyka i ulepszanie.
        </p>
        <p>
          <strong>Zgoda na cookies</strong>. Podczas pierwszej wizyty w naszym
          Serwisie wyświetlana jest Ci informacja na temat stosowania plików
          cookies. Zaakceptowanie i zamknięcie tej informacji oznacza, że
          wyrażasz zgodę na wykorzystywanie plików cookies zgodnie z
          postanowieniami niniejszej polityki prywatności. Zgodę zawsze możesz
          wycofać, usuwając pliki cookies oraz zmieniając ustawienia plików
          cookies w Twojej przeglądarce. Pamiętaj jednak, że wyłączenie plików
          cookies może powodować trudności w korzystaniu z Serwisu, jak również
          z wielu innych stron internetowych, które stosują cookies.
        </p>
        <p>
          <strong>Cookies własne</strong>. Pliki cookies można podzielić na
          własne oraz pochodzące od podmiotów trzecich. Jeżeli chodzi o cookies
          własne, wykorzystujemy je w celu poprawy działania Serwisu.
        </p>
        <p>
          <strong>Cookies podmiotów trzecich</strong>. Nasz Serwis, podobnie jak
          większość współczesnych stron internetowych, wykorzystuje funkcje
          zapewniane przez podmioty trzecie, co wiąże się z wykorzystywaniem
          plików cookies pochodzących od podmiotów trzecich. Wykorzystanie tego
          rodzaju plików cookies wygląda w naszym Serwisie następująco:
        </p>
        <p>
          <strong>Analiza i statystyka</strong>. Wykorzystujemy cookies do
          śledzenia statystyk strony, takich jak liczba osób odwiedzających,
          rodzaj systemu operacyjnego i przeglądarki internetowej
          wykorzystywanej do przeglądania strony, czas spędzony na stronie,
          odwiedzone podstrony etc. Korzystamy w tym zakresie z Google
          Analytics. Zbierane przez to narzędzie informacje są całkowicie
          anonimowe i nie pozwalają na Twoją identyfikację. W tym celu
          wykorzystywane są pliki cookies firmy Google LLC dotyczące usługi
          Google Analytics. Korzystanie z usług Google Analytics wiąże się z
          zaimplementowaniem w kodzie naszej strony udostępnionego przez Google
          kodu śledzącego (tzw. <em>tracking code</em>). Kod ten bazuje na
          plikach cookies, ale może wykorzystywać również inne technologie
          śledzące. Szczegółowe informacje o Google Analytics można znaleźć na
          stronie
          <a href='https://www.google.com/intl/en_us/analytics/'>
            https://www.google.com/intl/en_us/analytics/
          </a>
          . Możesz zapobiec przetwarzaniu danych osobowych w ten sposób
          (zablokować Google Analytics) dokonując odpowiednich wyborów w
          Serwisie dostępnym tutaj:
          <a href='https://tools.google.com/dlpage/gaoptout'>
            https://tools.google.com/dlpage/gaoptout
          </a>
          .
        </p>
        <p>
          Możesz samodzielnie i w każdym czasie zmienić ustawienia dotyczące
          plików cookies, określając warunki ich przechowywania i uzyskiwania
          dostępu przez pliki cookies do Twojego urządzenia. Zmiany ustawień, o
          których mowa w zdaniu poprzednim, możesz dokonać za np. pomocą
          ustawień przeglądarki internetowej. Ustawienia te mogą zostać
          zmienione w szczególności w taki sposób, aby blokować automatyczną
          obsługę plików cookies w ustawieniach przeglądarki internetowej bądź
          informować o ich każdorazowym zamieszczeniu cookies na Twoim
          urządzeniu. Szczegółowe informacje o możliwości i sposobach obsługi
          plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki
          internetowej) lub na stronach:
        </p>
        <p>
          <strong>Mozilla Firefox</strong>
        </p>
        <p>
          <a href='https://support.mozilla.org/pl/kb/usuwanie-ciasteczek#w_usuwanie-wszystkich-ciasteczek'>
            https://support.mozilla.org/pl/kb/usuwanie-ciasteczek#w_usuwanie-wszystkich-ciasteczek
          </a>
        </p>
        <p>
          <strong>Safari </strong>
        </p>
        <p>
          <a href='https://support.apple.com/kb/ph21411?locale=pl_PL'>
            https://support.apple.com/kb/ph21411?locale=pl_PL
          </a>
        </p>
        <p>
          <strong>Google Chrome</strong>
        </p>
        <p>
          <a href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=pl'>
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=pl
          </a>
        </p>
        <p>
          Możesz również w każdej chwili usunąć pliki cookies korzystając z
          dostępnych funkcji w przeglądarce internetowej, której używasz, przy
          czym ograniczenie stosowania plików cookies, może wpłynąć na niektóre
          funkcjonalności dostępne na stronie internetowej.
        </p>
        <h2>Jakie dane osobowe przetwarzamy</h2>
        <p>
          Zawsze staramy się by przetwarzać tylko te dane osobę które są
          niezbędne i w minimalnym zakresie, przy czym możemy przetwarzać
          następujące Twoje dane osobowe:
        </p>
        <p>1) adres email,</p>
        <p>2) adres IP,</p>
        <p>3) data i czas serwera,</p>
        <p>
          4) lokalizacja urządzenia końcowego z którego użytkownik łączy się z
          Serwisem,
        </p>
        <p>
          5) parametry techniczne urządzenia i oprogramowania wykorzystywanego
          przez użytkownika,
        </p>
        <p>6) język interfejsu urządzenia,</p>
        <p>
          7) tagi UTM, określające z jakiej lokalizacji sieciowej przybył
          użytkownik,
        </p>
        <p>
          8) dane o przeglądanych w Serwisie treściach (np. sposób przechodzenia
          pomiędzy podstronami Serwisu),
        </p>
        <p>
          9) identyfikatory online, w tym identyfikatory plików cookie, adresy
          protokołów internetowych i identyfikatory urządzeń (na potrzeby
          wykorzystania plików cookies),
        </p>
        <p>
          10) dane konieczne do realizacji płatności za pośrednictwem
          zewnętrznego operatora płatności internetowych oraz wystawienia
          rachunku lub faktury,
        </p>
        <p>a niekiedy również:</p>
        <p>
          11) dane związane z wykonywaniem przez Ciebie uprawnień z reklamacji
          usług świadczonych drogą elektroniczną, oraz
        </p>
        <p>
          12) inne dane, które potencjalnie mogą być zawarte w treści
          kierowanych przez Ciebie do nas wiadomościach.{' '}
        </p>
        <p>Komu ujawniamy Twoje dane osobowe</p>
        <p>
          Dane wszystkich użytkowników Serwisu przetwarzane są w systemie
          informatycznym, znajdującym się w części w tzw. publicznej chmurze
          obliczeniowej dostarczanej przez podmioty trzecie (odpowiadające za
          hosting Serwisu).
        </p>
        <p>Powierzamy przetwarzanie danych osobowych następującym podmiotom:</p>
        <p>
          - nazwa.pl sp. z o.o. z siedzibą w Krakowie, ul. Mieczysława
          Medweckiego 17, 31-870 Kraków, wpisana do Rejestru Przedsiębiorców
          Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla
          Krakowa – Śródmieścia w Krakowie, XI Wydział Gospodarczy Krajowego
          Rejestru Sądowego pod numerem KRS: 0000594747, NIP: 6751402920, REGON:
          120805512 – w celu przechowywania danych na serwerze w ramach usługi
          hostingu. Nasz Serwis znajduje się na tych serwerach, wszystko co
          wpiszesz w naszych formularzach musi tam się pojawić. Twoje dane są
          przechowywane na bezpiecznych serwerach w Polsce. Połączenia, których
          używamy do łączenia się z serwerem są szyfrowane,
        </p>
        <p>
          - Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
          Irlandia – w celu obsługi naszej korespondencji mailowej oraz do
          wykorzystywania oferowanych przez Google narzędzi analitycznych i
          statystycznych (Google Analytics).
        </p>
        <p>
          Niektóre z opisanych wyżej operacji wiążą się z przesyłaniem Twoich
          danych osobowych do tzw. krajów trzecich (poza Europejski Obszar
          Gospodarczy), gdzie nie ma zastosowania RODO. Dzieje się to jednak
          zawsze w oparciu o instrumenty prawne przewidziane w RODO,
          gwarantujące adekwatną ochronę Twoich praw i wolności. Szczegółowe
          informacje w tym zakresie możesz znaleźć w politykach prywatności
          umieszczanych przez dostawców w ich serwisach.
        </p>
        <p>
          Musisz również wiedzieć, że w naszej działalności korzystamy ze
          wsparcia wyspecjalizowanych zewnętrznych podmiotów, które mogą lub
          muszą mieć dostęp do niektórych Twoich danych – są to podmioty
          świadczące dla nas usługi z zakresu:
        </p>
        <p>- obsługi księgowej,</p>
        <p>- obsługi systemu informatycznego,</p>
        <p>
          W stosownych umowach z tymi podmiotem zagwarantowaliśmy, że powierzone
          im Twoje dane mają być chronione zgodnie z RODO i nie będą
          transferowane do krajów trzecich.
        </p>
        <p>
          Ponadto, Twoje dane osobowe ujawniamy w wymaganym zakresie organom
          administracji państwowej, uprawnionym do tego z mocy prawa (jak np.
          organy podatkowe).
        </p>
        <h2>Jak długo będziemy przetwarzać Twoje dane osobowe</h2>
        <p>
          Twoje dane osobowe związane z korzystaniem przez Ciebie z prowadzonego
          przez nas Serwisu, niezbędne w celu prowadzenia Twojego konta w
          Serwisie, będziemy przetwarzać przez cały okres współpracy, czyli
          utrzymania konta w Serwisie.
        </p>
        <p>
          Również, jeśli na podstawie przepisów prawa lub umowy przysługują Ci
          jakiekolwiek uprawnienia (np. z tytułu reklamacji), to przez cały
          okres ich trwania musimy przetwarzać Twoje dane osobowe by móc w razie
          potrzeby służyć Ci pomocą w tym zakresie.
        </p>
        <p>
          Przetwarzanie Twoich danych osobowych zawartych w plikach cookies trwa
          do czasu wyłączenia możliwości ich wykorzystywania (są to jednak
          przechowywane w formie zanonimizowanej). Możesz to zrobić usuwając
          pliki cookies oraz zmieniając ustawienia plików cookies w Twojej
          przeglądarce.
        </p>
        <p>
          Dane osobowe udostępnione za pośrednictwem formularzy kontaktowych
          będą przechowywane nie dłużej, niż jest to konieczne do udzielenia Ci
          odpowiedzi, a po tym czasie mogą być przechowywane jedynie na wypadek
          potencjalnych roszczeń przez okres ich przedawnienia określony
          przepisami.
        </p>
        <h2>Jak umożliwiamy Ci realizację Twoich praw</h2>
        <p>
          Dokładamy wszelkich starań, abyś był zadowolony ze współpracy z nami.
          Pamiętaj jednak, że przysługuje Ci wiele uprawnień, które pozwolą Ci
          wpłynąć na sposób w jaki przetwarzamy Twoje dane osobowe, a w
          niektórych wypadkach spowodować zaprzestanie takiego przetwarzania. Te
          prawa to:
        </p>
        <ul>
          <li>
            prawo dostępu do danych osobowych (uregulowane w art. 15 RODO)
          </li>
          <li>prawo do sprostowania danych (uregulowane w art. 16 RODO)</li>
          <li>prawo do usunięcia danych (uregulowane w art. 17 RODO)</li>
          <li>
            prawo do ograniczenia przetwarzania (uregulowane w art. 18 RODO)
          </li>
          <li>
            prawo do wniesienia sprzeciwu wobec przetwarzania (uregulowane w
            art. 21 RODO)
          </li>
          <li>prawo do przenoszenia danych (uregulowane w art. 20 RODO)</li>
        </ul>
        <p>
          W celu realizacji praw prosimy o kontakt poprzez adres email{' '}
          {contactEmail}
        </p>
        <h2>Skarga do organu nadzorczego</h2>
        <p>
          Zgodnie z art. 77 RODO przysługuje Ci prawo wniesienia skargi do
          organu nadzorczego, w szczególności w państwie członkowskim swojego
          zwykłego pobytu, swojego miejsca pracy lub miejsca popełnienia
          domniemanego naruszenia, jeżeli sądzisz, że przetwarzanie danych
          osobowych Ciebie dotyczące narusza przepisy RODO. W Polsce organem
          nadzorczym jest Prezes Urzędu Ochrony Danych Osobowych – możesz złożyć
          skargę m.in. pocztą tradycyjną na adres ul. Stawki 2, 00-913 Warszawa
          lub pocztą elektroniczną na adres{' '}
          <a href='mailto:kancelaria@uodo.gov.pl'>kancelaria@uodo.gov.pl</a>,
          możesz również uzyskać bardziej szczegółowe informacje (w tym aktualne
          numery telefonów) na stronie internetowej:{' '}
          <a href='https://uodo.gov.pl/'>https://uodo.gov.pl/</a>.
        </p>
        <h2>Czy podanie danych jest konieczne do zawarcia umowy z nami</h2>
        <p>
          Zbieramy Twoje dane osobowe przede wszystkim w zakresie koniecznym do
          zawarcia i wykonania umowy o świadczenie usług drogą elektroniczną w
          zakresie dostępu do funkcjonalności Serwisu. Część danych jest również
          niezbędna do realizacji przez nas obowiązków wynikających z przepisów
          prawa (przepisy podatkowe, przepisy o rachunkowości, obowiązki
          prokonsumenckie). Niepodanie danych osobowych przez Ciebie niestety
          uniemożliwi zawarcie i realizację ww. umowy.
        </p>
        <p>
          Natomiast, Twoje dane eksploatacyjne nie są zbierane w celu zawarcia z
          Tobą umowy.
        </p>
        <p>
          Podanie przez Ciebie wybranych danych osobowych w formularzu
          kontaktowym zamieszczonym w Serwisie jest całkowicie dobrowolne, ale
          konieczne do tego, żeby odpowiedzieć na Twoje pytanie. Nie musisz
          jednak korzystać z formularza kontaktowego, aby rozpocząć współpracę.
          Możesz również skontaktować się z nami za pośrednictwem e-mail na
          adres: {contactEmail}.
        </p>
        <p>
          Co istotne, nie musisz udostępniać nam informacji zawartych w plikach
          cookies. Możesz temu zapobiec poprzez usunięcie plików cookies oraz
          zmianę ustawień plików cookies w Twojej przeglądarce. Szczegółowe
          informacje o możliwości i sposobach obsługi plików cookies dostępne są
          w ustawieniach oprogramowania (przeglądarki internetowej) np.:
        </p>
        <p>
          <a
            href='http://support.mozilla.org/pl/kb/ciasteczka'
            target='_blank'
            rel='noreferrer'
          >
            w przeglądarce Mozilla Firefox
          </a>
          <br />
          <a
            href='http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647'
            target='_blank'
            rel='noreferrer'
          >
            w przeglądarce Chrome
          </a>
          <br />
          <a
            href='http://support.apple.com/kb/HT1677?viewlocale=pl_PL&amp;locale=pl_PL'
            target='_blank'
            rel='noreferrer'
          >
            w przeglądarce Safari
          </a>
        </p>
        <p>
          Pamiętaj jednak, że zmiana ustawień plików cookies w ten sposób, że
          zablokowana zostanie możliwość wykorzystania informacji w nich
          zawartych, może powodować trudności w korzystaniu z Serwisu.
        </p>
        <h2>Skąd mamy Twoje dane osobowe</h2>
        <p>Twoje dane osobowe pozyskujemy wyłącznie od Ciebie.</p>
        <p>
          Dane eksploatacyjne i związane z wykorzystaniem cookies pozyskujemy w
          sposób automatyczny – nie są to jednak dane osobowe.
        </p>
        <h2>Przetwarzanie zautomatyzowane i profilowanie</h2>
        <p>
          Dane eksploatacyjne i związane z wykorzystaniem cookies są
          przetwarzane w sposób zautomatyzowany (nie są one jednak poddawane
          profilowaniu w rozumieniu przyjętym przez RODO).
        </p>
        <p>
          Pozostałe dane użytkowników Serwisu, w tym dane osób korzystających z
          formularza kontaktowego, nie są przetwarzane w sposób zautomatyzowany
          ani poddawane profilowaniu.
        </p>
      </Container>
    </PageContainer>
  );
}
