import './navbar-navigation.sass';
import React from 'react';
import { Navigation } from './navigation';
import { Tab, Tabs } from '@material-ui/core';
import { capitalizeFirstLetter } from 'public/util';
import { useT } from 'public/hooks/translation';
import { merge } from 'public/css';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Favorite from '@material-ui/icons/Favorite';
import RestoreIcon from '@material-ui/icons/Restore';

export interface Props {
  className?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    indicator: {
      background: 'none'
    }
  })
);

export function NavbarNavigation({ className }: Props): JSX.Element {
  const t = useT();
  const classes = useStyles();

  return (
    <Navigation>
      {(value, onChange) => (
        <Tabs
          classes={classes}
          className={merge('navbar-navigation-root', className)}
          value={value}
          onChange={onChange}
          aria-label={'main navigation'}
        >
          <Tab
            label={
              <>
                <RestoreIcon />
                <span>{capitalizeFirstLetter(t('recent'))}</span>
              </>
            }
          />
          <Tab
            label={
              <>
                <Favorite />
                <span>{capitalizeFirstLetter(t('watchlist'))}</span>
              </>
            }
          />
        </Tabs>
      )}
    </Navigation>
  );
}
