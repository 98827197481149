import React from 'react';
import {
  BottomNavigation as BottomNavigationMaterial,
  BottomNavigationAction
} from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import { useCT } from 'public/hooks/translation';
import { Navigation } from './navigation';
import Favorite from '@material-ui/icons/Favorite';

export function BottomNavigation(): JSX.Element {
  const ct = useCT();

  return (
    <Navigation>
      {(value, onChange) => (
        <BottomNavigationMaterial value={value} onChange={onChange} showLabels>
          <BottomNavigationAction label={ct('recent')} icon={<RestoreIcon />} />
          <BottomNavigationAction label={ct('watchlist')} icon={<Favorite />} />
        </BottomNavigationMaterial>
      )}
    </Navigation>
  );
}
