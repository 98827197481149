import React from 'react';
import Button from '@material-ui/core/Button';
import './cookies-accept-request.sass';
import { useT } from 'public/hooks/translation';
import { capitalizeFirstLetter } from 'public/util';
import { useCookiesAgreement } from '../hooks/cookies-agreement';
import { useCompanyDetails } from 'public/providers/company-details-provider';

export interface Props {
  onAccept: () => void;
}

export function CookiesAcceptRequest({ onAccept }: Props): JSX.Element | null {
  const t = useT();
  const { value: hide, accept } = useCookiesAgreement();
  const { PrivacyPolicyLink } = useCompanyDetails();

  if (hide) {
    return null;
  }
  return (
    <div className={'cookies-accept-request-root'}>
      <div className={'container'}>
        {t('cookie info')}{' '}
        <PrivacyPolicyLink>
          {capitalizeFirstLetter(t('learn more'))}
        </PrivacyPolicyLink>
      </div>
      <div className={'container'}>
        <Button
          onClick={() => {
            accept();
            onAccept();
          }}
        >
          {capitalizeFirstLetter(t('I accept'))}
        </Button>
      </div>
    </div>
  );
}
