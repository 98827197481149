import React, { useRef } from 'react';
import { merge } from 'public/css';
import { PageContainer } from 'public/components/page-container';
import { AdsBrowserNavbar } from '../components/ads-browser-navbar';
import {
  Container,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { AdsList } from 'app/ads-browser/components/ads-list';
import { GA_EVENTS } from 'app/root/utils/google-analytics';
import { ResultsData } from 'app/ads-browser/providers/results-provider';
import { Select } from 'public/forms/components/select';
import { useCT } from 'public/hooks/translation';
import { Filter } from 'public/auth/models/user';
import { useFilterApi } from 'app/ads-browser/providers/filter-provider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { WithClassName } from 'public/props';

const useStyles = makeStyles(theme =>
  createStyles({
    filterDropdown: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  })
);

export function Results({ className }: WithClassName): JSX.Element {
  const classes = useStyles();
  const ct = useCT();
  const theme = useTheme();
  const isScreenNarrow = useMediaQuery(theme.breakpoints.down('md'));
  const mobileScrollContainer = useRef(null);
  const desktopScrollContainer = useRef(null);
  const {
    available,
    getName,
    current: currentFilter,
    setFilter
  } = useFilterApi();

  return (
    <PageContainer
      contentScrollContainerRef={mobileScrollContainer}
      className={merge('results-root', className)}
      scrollElement={isScreenNarrow ? 'CONTENT' : 'NOTHING'}
      navbar={<AdsBrowserNavbar />}
    >
      <Container>
        <FormControl fullWidth className={classes.filterDropdown}>
          <InputLabel id={'filter-label'}>{ct('chosen filter')}</InputLabel>
          <Select<Filter['id']>
            variant={'filled'}
            labelId={'filter-label'}
            value={currentFilter.id}
            placeholder={ct('choose filter')}
            options={available.map(item => item.id)}
            getLabel={filterId => getName(filterId) || ''}
            onChange={event => setFilter(event.target.value as Filter['id'])}
          />
        </FormControl>
      </Container>
      <div
        className={merge('flex-1', !isScreenNarrow && 'scroll-here')}
        ref={desktopScrollContainer}
      >
        <ResultsData.Wrapper className={'flex-1'}>
          {({ data, hasMore, loadMore, className }) => (
            <Container>
              <AdsList
                className={className}
                loadMore={() => {
                  GA_EVENTS.query.loadMore();
                  loadMore();
                }}
                hasMore={hasMore}
                data={data}
                getScrollParent={() =>
                  isScreenNarrow
                    ? mobileScrollContainer.current
                    : desktopScrollContainer.current
                }
              />
            </Container>
          )}
        </ResultsData.Wrapper>
      </div>
    </PageContainer>
  );
}
