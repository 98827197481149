import {
  Ad as AdModel,
  Enum_Ad_State,
  useAddToWatchlistMutation,
  useRemoveFromWatchlistMutation,
  useUpdateAdMutation
} from 'generated/graphql';
import { MutatingState } from 'public/graphql/models';
import { ContextHookFactory } from 'public/utils/context-hook';
import { MutationWrapper } from 'public/requests/components/MutationWrapper';
import { maybePassProps } from 'public/util';
import { ProviderComponentProps } from 'public/components/provider-group';

type MutationLabels = 'updating' | 'adding' | 'removing';
type TData = Pick<AdModel, 'id' | 'state'>;

export interface Mutations {
  update: (val: Enum_Ad_State) => Promise<void>;
  addToWatchlist: () => Promise<void>;
  removeFromWatchlist: () => Promise<void>;
  setNote: (note: string) => Promise<void>;
  setVisited: () => Promise<void>;
}

export type Hook = [Mutations, MutatingState<MutationLabels>];

export const AdContext = ContextHookFactory.createContext<TData>('Ad');
export const useAd = ContextHookFactory.createHook<TData>(AdContext);

export function useAdUpdate() {
  const ad = useAd();
  const { trigger, ...rest } = useUpdateAdMutation({
    variables: { id: ad.id }
  });
  return {
    ...rest,
    async trigger(val: Enum_Ad_State): Promise<void> {
      await trigger({ variables: { id: ad.id, data: { state: val } } });
    }
  };
}

export function useAdAddToWatchlist() {
  const ad = useAd();
  const { trigger, ...rest } = useAddToWatchlistMutation({
    variables: { id: ad.id }
  });
  return {
    ...rest,
    async trigger(): Promise<void> {
      await trigger({ variables: { id: ad.id } });
    }
  };
}

export function useAdRemoveFromWatchlist() {
  const ad = useAd();
  const { trigger, ...rest } = useRemoveFromWatchlistMutation({
    variables: { id: ad.id }
  });
  return {
    ...rest,
    async trigger(): Promise<void> {
      await trigger({ variables: { id: ad.id } });
    }
  };
}

export function useAdSetVisited() {
  const ad = useAd();
  const { trigger, ...rest } = useUpdateAdMutation({
    variables: { id: ad.id }
  });
  return {
    ...rest,
    async trigger(): Promise<void> {
      if (!ad || ad.state !== 'new') {
        return;
      }
      await trigger({
        variables: { id: ad.id, data: { state: 'visited' } }
      });
    }
  };
}

export function useAdSetNote() {
  const ad = useAd();
  const { trigger, ...rest } = useUpdateAdMutation({
    variables: { id: ad.id }
  });
  return {
    ...rest,
    async trigger(note: string): Promise<void> {
      await trigger({
        variables: { id: ad.id, data: { note } }
      });
    }
  };
}

export interface Props extends ProviderComponentProps {
  className?: string;
}

export function AdMutationsWrapper({
  children,
  className
}: Props): JSX.Element {
  const { trigger: update, loading: updating } = useAdUpdate();
  const { trigger: addToWatchlist, loading: addingToWatchlist } =
    useAdAddToWatchlist();
  const { trigger: removeFromWatchlist, loading: removingToWatchlist } =
    useAdRemoveFromWatchlist();
  const { trigger: setVisited, loading: settingVisited } = useAdSetVisited();
  const { trigger: setNote, loading: settingNote } = useAdSetNote();
  const api = {
    update,
    addToWatchlist,
    removeFromWatchlist,
    setNote,
    setVisited
  };
  const mutating =
    updating || settingNote || settingVisited
      ? 'updating'
      : removingToWatchlist
      ? 'removing'
      : addingToWatchlist
      ? 'adding'
      : false;
  return (
    <MutationWrapper mutating={mutating} className={className}>
      {maybePassProps<Mutations>(children, api)}
    </MutationWrapper>
  );
}
