import urljoin from 'url-join';
import { TERMS_OF_SERVICE } from 'app/root/models/urls';

export const CONTACT_EMAIL = 'turboitservices@gmail.com';
export const COMPANY_NAME = 'Kamil Neczaj TurboITServices';
export const NIP = '9222958027';
export const REGON = '365744048';
export const ADDRESS = ['ul. M. Zyblikiewicza 16/9', '31-029 Kraków'];
export const SERVICE_NAME = 'Wyszukiwarka Nieruchomości';
export const DEPLOYMENT_ADDRESS = 'https://obserwuj.net/';
export const TERMS_OF_SERVICE_URL = urljoin(
  DEPLOYMENT_ADDRESS,
  TERMS_OF_SERVICE
);
