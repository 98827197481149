import React from 'react';
import { Navbar } from '../../shared/components/navbar';
import { NavbarNavigation } from '../navigation/components/navbar-navigation';
import { useNavigationVariant } from '../navigation/hooks/useNavigationVariant';

export function AdsBrowserNavbar(): JSX.Element {
  const { isWide } = useNavigationVariant();
  return (
    <Navbar>
      {isWide && <NavbarNavigation className={'align-self-end'} />}
    </Navbar>
  );
}
