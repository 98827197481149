import React from 'react';
import { Offer as OfferModel } from 'app/ads-browser/models/offer';
import { Offer } from 'app/ads-browser/components/offer';
import InfiniteScroll from 'react-infinite-scroller';
import './ads-list.sass';
import { merge } from 'public/css';
import { AdFragmentFragment } from 'generated/graphql';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';

interface StylesProps {
  itemsPerRow: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) => ({
  wrapper: ({ itemsPerRow }: StylesProps) => ({
    width: `${100 / itemsPerRow}%`,
    display: 'flex',
    flexDirection: 'column'
  })
}));

export interface Props {
  getScrollParent?(): HTMLElement | null;
  loadMore: () => void;
  hasMore: boolean;
  data: AdFragmentFragment[];
  className?: string;
}

export function AdsList({
  className,
  getScrollParent,
  hasMore,
  data,
  loadMore
}: Props): JSX.Element {
  const theme = useTheme();
  const twoItems = useMediaQuery(theme.breakpoints.up('md'));
  const threeItems = useMediaQuery(theme.breakpoints.up('xl'));
  const itemsPerRow = threeItems ? 3 : twoItems ? 2 : 1;
  const classes = useStyles({ itemsPerRow });
  const toHide = data.length % itemsPerRow;
  // remove elements which do not form a full row (only if these are not all the items)
  const dataTrimmed = toHide && hasMore ? data.slice(0, -toHide) : data;
  return (
    <InfiniteScroll
      element={'div'}
      className={merge('list-container', className)}
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={undefined}
      useWindow={false}
      getScrollParent={getScrollParent}
    >
      {dataTrimmed.map((offer: OfferModel) => (
        <Offer key={offer.id} data={offer} className={classes.wrapper} />
      ))}
    </InfiniteScroll>
  );
}
