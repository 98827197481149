import { useState } from 'public/hooks/state';

export interface Hook {
  value: boolean;
  accept: () => void;
}

export function useCookiesAgreement(): Hook {
  const { value, set } = useState<boolean>(
    localStorage.getItem('cookies') === 'yes'
  );
  function accept() {
    localStorage.setItem('cookies', 'yes');
    set(true);
  }
  return {
    value,
    accept
  };
}
