import {
  ProviderComponent,
  ProviderComponentProps
} from 'public/components/provider-group';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as ApolloProviderBase,
  FieldFunctionOptions,
  InMemoryCache
} from '@apollo/client';
import React, { useMemo } from 'react';
import { setContext } from '@apollo/client/link/context';
import { isNull } from 'public/util';
import { createUploadLink } from 'apollo-upload-client';
import { withScalars } from 'apollo-link-scalars';
import { getDateTimeScalar } from 'public/graphql/scalars/date-time';
import { useToken } from 'public/auth/providers/token-provider';
import { getDateScalar } from 'public/graphql/scalars/date';
import { AdQueryVariables, AdsQueryVariables } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { getBackendUrl } from 'app/root/models/urls';
import { buildClientSchema } from 'graphql';
import introspectionResult from 'generated/graphql.schema.json';

const schema = buildClientSchema(introspectionResult as any);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ads: {
          // Changing fielters will invalidate cache
          keyArgs: ['where'],
          // Concatenate the incoming list items with
          // the existing list items.
          // NOTE: needed for pagination
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
          // @ts-ignore
          read(existing, { args }: FieldFunctionOptions<AdsQueryVariables>) {
            if (isNull(args)) {
              return existing;
            }
            const { start, limit } = args;
            return existing && existing.slice(start, start + limit);
          }
        },
        ad: {
          // reads Ads cached from `ads` query
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Ad',
              id: (args as AdQueryVariables).id
            });
          }
        }
      }
    }
  }
});

export const ApolloProvider: ProviderComponent = ({
  children
}: ProviderComponentProps) => {
  const { token } = useToken();
  const translation = useTranslation();
  const httpLink = useMemo(
    () =>
      ApolloLink.from([
        withScalars({
          schema,
          typesMap: {
            DateTime: getDateTimeScalar(translation.i18n.language),
            Date: getDateScalar(translation.i18n.language)
          }
        }) as unknown as ApolloLink,
        // omitTypenameLink,
        createUploadLink({
          uri: getBackendUrl(`/graphql`)
        }) as unknown as ApolloLink
      ]),
    [translation]
  );
  const authLink = useMemo(() => {
    if (isNull(token)) {
      return null;
    }
    return setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      };
    });
  }, [token]);

  const client = useMemo(() => {
    return new ApolloClient({
      link: isNull(authLink) ? httpLink : authLink.concat(httpLink),
      cache
    });
  }, [authLink, httpLink]);
  return <ApolloProviderBase client={client}>{children}</ApolloProviderBase>;
};
