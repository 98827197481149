import i18nBase from 'i18next';
import React from 'react';
import { GA_EVENTS } from './root/utils/google-analytics';
import { getI18n } from 'public/i18n';
import { CONTACT_EMAIL } from 'branded/config';
import {
  PrivacyPolicyLink,
  TermsOfServiceLink
} from 'branded/providers/company-details-provider';

export const translationResources = {
  pl: {
    translation: {
      city: 'miasto',
      location: 'Lokalizacja',
      type: 'Typ nieruchomości',
      rooms_number: 'liczba pokoi',
      size: 'powierzchnia',
      'ad-type': 'Typ ogłoszenia',
      max_price: 'Cena maksymalna',
      agency: 'Agencja',
      private: 'Prywatne',
      add: 'dodaj',
      delete: 'usuń',
      search: 'szukaj',
      query: 'ustal kryteria',
      results: 'przejrzyj wyniki',
      location_placeholder:
        'ulica, dzielnica, część miasta, która zawiera się w opisie itd.',
      from: 'od',
      upto: 'do',
      price: 'cena',
      required: 'pole jest wymagane',
      numbers_only: 'tylko liczby',
      natural_numbers_only: 'tylko liczby naturalne',
      alpha_only: 'tylko słowa',
      at_least_one_required: 'co najmniej jedna wartość jest wymagana',
      improper_range: 'niepoprawny zakres',
      login: 'zaloguj',
      register: 'zarejestruj',
      username: 'email',
      password: 'hasło',
      password1: 'hasło',
      password2: 'powtórz hasło',
      'log in': 'logowanie',
      registration: 'rejestracja nowego użytkownika',
      must_be_greater_than: 'wartość musi być większa niż {{ threshold }}',
      logout: 'wyloguj',
      'try out': 'wypróbuj',
      'how does it work': 'jak to działa',
      'register or login': 'zarejestruj się lub zaloguj jeśli masz konto',
      'registration successfull': 'rejestracja zakończona sukcesem',
      'move to login page': 'przejdź do ekranu logowania',
      RentType0: 'pokój',
      RentType1: 'mieszkanie',
      RentType2: 'dom',
      RentType4: 'dowolny',
      'state criteria': 'podaj kryteria',
      'already have an account? - Log in': 'masz już konto? - Zaloguj',
      'email required': 'to nie jest adres email',
      "I don't have an account - register me":
        'Nie mam jeszcze konta - zarejestruj mnie',
      cancel: 'anuluj',
      'choose a city': 'wybierz miasto',
      'choose a value': 'wybierz wartość',
      krakow: 'Kraków',
      wroclaw: 'Wrocław',
      modify: 'modyfikuj',
      filters: 'filtry',
      area: 'powierzchnia',
      'number of rooms': 'liczba pokoi',
      'adding time': 'czas dodania',
      'N/A': 'niedostępne', // N/A - not available abbreviation,
      contact: 'kontakt',
      'the icons provider is': 'ikony dostarczone przez',
      under: 'pod',
      'free license': 'wolną licencją',
      buy: 'kup',
      days: 'dni',
      PLN: 'zł',
      'your data': 'twoje dane',
      active: 'aktywna',
      future: 'przyszłe',
      past: 'przeszłe',
      'your subscription is valid until': 'twoja subskrypcja jest ważna do',
      extend: 'rozszerz',
      profile: 'profil',
      'your account is being activated': 'Twoje konto jest aktywowane',
      'account-activating-message':
        'Sprawdzamy poprawność linku aktywacyjnego. Za chwilę zostaniesz przekierowany dalej',
      'your account needs verification': 'Twoje konto wymaga weryfikacji',
      'account-verification-message':
        'W celu aktywacji konta kliknij na link w wiadomości wysłanej na Twój adres email.',
      'the activation link is invalid or expired':
        'link aktywacyjny jest niepoprawny lub wygasł',
      'account-activation-error-message': `Link aktywacyjny, którego używasz nie jest poprawny. Skontaktuj się z nami poprzez ${CONTACT_EMAIL}.`,
      'successful-verification-message':
        'Twoje konto zostało poprawnie zweryfikowane.',
      'You can login now.': 'Możesz się teraz zalogować.',
      since: 'od',
      until: 'do',
      'valid since': 'ważna od',
      'valid until': 'ważna do',
      room_0: 'pokój',
      room_1: 'pokoje',
      room_2: 'pokoi',
      info: 'informacje',
      'privacy policy': 'polityka prywatności',
      'terms of service': 'regulamin usługi',
      licenses: 'licencje',
      accept: 'zaakceptuj',
      privacyPolicy: (
        <>
          akceptuję
          <PrivacyPolicyLink
            className={'ml-1'}
            onClick={() => GA_EVENTS.registration.visitPrivacyPolicy()}
          >
            Politykę Prywatności
          </PrivacyPolicyLink>
        </>
      ),
      terms: (
        <>
          akceptuję
          <TermsOfServiceLink
            className={'ml-1'}
            onClick={() => GA_EVENTS.registration.visitTermsOfUse()}
          >
            Regulamin
          </TermsOfServiceLink>
        </>
      ),
      return: 'powrót',
      'A user is already registered with this e-mail address.':
        'Jest już użytkownik zarejestrowany z tym adresem email.',
      // this is translated to empty string because username and email fields duplicate, and this error is already shown
      // at email field
      'A user with that username already exists.': '',
      'Unable to log in with provided credentials.': 'Błędny email lub hasło.',
      'buy and pay': 'kup i zapłać',
      'order summary': 'podsumowanie zamówienia',
      activation_requested: `chcę aby usługa została zrealizowana natychmiast, a subskrypcja aktywowana niezwłocznie
        po jej zamówieniu, co będzie skutkowało utratą prawa do odstąpienia od umowy.`,
      must_not_contain: 'nie może zawierać słów',
      must_contain: 'musi zawierać słowa',
      'no keywords': 'brak słów kluczowych',
      or: 'lub',
      'This field is required.': 'pole jest wymagane',
      'must be longer than': 'musi być dłuższy niż {{threshold}}',
      'must have length equal': 'musi mieć długość {{threshold}} znaków',
      'codes length is equal': 'kody mają długość {{threshold}} znaków',
      contains: 'zawiera',
      'does not contain': 'nie zawiera',
      'no results': 'brak rezultatów',
      'modify filters': 'modyfikuj filtry',
      'there are no offers satisfying all of the following criteria':
        'brak ofert spełniających wszystkie poniższe kryteria',
      'contains all the words':
        'zawiera wszystkie ze słów (lub ich odmienione formy)',
      'does not contain any of the words':
        'nie zawiera żadnego ze słów (ani ich odmienionych form)',
      save: 'zapisz',
      keywords_duplicated_singular:
        'słowo {{enumeration}} jednocześnie nie może być w słowach wymaganych i zakazanych',
      keywords_duplicated_plural:
        'słowa {{enumeration}} jednocześnie nie mogą być w słowach wymaganych i zakazanych',
      whitelisted_keyword_duplicated_singular:
        'słowo {{enumeration}} nie może być dodane dwa razy',
      whitelisted_keyword_duplicated_plural:
        'słowa {{enumeration}} nie mogą być dodane dwa razy',
      'add synonym': 'dodaj synonim',
      code: 'kod promocyjny',
      'Provided code has expired': 'podany kod wygasł',
      'Provided code does not exist': 'podany kod nie istnieje',
      'A user has already used one promo code':
        'użytkownik już raz skorzystał z kodu promocyjnego',
      'save filters': 'zapisz filtry',
      make_account_to_save:
        'Utwórz konto, aby zapisać filtry i oszczędzić czas na wpisywaniu parametrów wyszukiwania przy następnej wizycie.',
      log_in_to_save:
        'Zaloguj się, aby zapisać filtry i oszczędzić czas na wpisywaniu parametrów wyszukiwania przy następnej wizycie.',
      "The two password fields didn't match.": 'Podane hasła różnią się',
      success: 'sukces',
      'the query has been saved': 'filtry zostały zapisane',
      close: 'zamknij',
      'Filters has been saved on the account which just has been created':
        'Filtry są zapisane na koncie, które właśnie stworzyłeś.',
      'This password is too short. It must contain at least 8 characters.':
        'To hasło jest za krótkie. Powinno zawierać chociaż 8 znaków.',
      'choose an offer to display': 'wybierz ofertę',
      source: 'źródło',
      'go back': 'powrót',
      room_short: 'pok.',
      warszawa: 'Warszawa',
      poznan: 'Poznań',
      gdansk: 'Gdańsk',
      and: 'i',
      'is already among keywords': 'jest już pośród innych słów',
      'I accept': 'akceptuję',
      'no limits': 'bez ograniczeń',
      send: 'wyślij',
      message: 'wiadomość',
      email: 'adres e-mail',
      'Give us some feedback': 'Co powinniśmy ulepszyć?',
      'Your message was successfully sent.': 'Twoja wiadomość została wysłana',
      'There was an error while sending your message.':
        'Podczas wysyłania Twojej wiadomości wystąpił błąd',
      'Access to rental ads from one place':
        'Dostęp do ogłoszeń wynajmu z jednego miejsca',
      'Define search params for all ads sources at one place':
        'Definiujesz parametry wyszukiwania tylko raz, nieważne skąd pochodzi oferta',
      "Don't lose time on browsing duplicate offers":
        'Nie tracisz czasu na przeglądanie zduplikowanych ogłoszeń',
      'Use keywords to find exactly what you need':
        'Użyj słów kluczowych aby znaleźć dokładnie to, czego potrzebujesz',
      'An ad appears in internet': 'Ogłoszenie pojawia się w Internecie',
      'Looking for a flat?': 'Szukasz mieszkania?',
      'here you can find it': 'tutaj je znajdziesz',
      landing_header1:
        'Dokładnie te oferty, których\u00A0potrzebujesz, nieważne\u00A0gdzie się\u00A0pojawiły',
      'learn more': 'dowiedz się więcej',
      'Usually when you search for a flat you browse couple of web pages':
        'Najczęściej szukając mieszkania na wynajem przeglądamy kilka serwisów',
      'There is a better way': 'Jest lepszy sposób',
      'What are your benefits?': 'Co dostajesz?',
      'Choose your city': 'Wybierz swoje miasto',
      'Find it at LokumKum in a moment': (
        <>
          <span className={'no-wrap'}>Po chwili</span>{' '}
          <span className={'no-wrap'}>jest widoczne</span>{' '}
          <span className={'no-wrap'}>w LokumKum</span>
        </>
      ),
      'rental offers': 'ofert wynajmu',
      'in the last two weeks': 'z ostatnich dwóch tygodni',
      'cookie info':
        'Korzystając z naszych usług, przyjmujesz do wiadomości, że używamy plików cookie do analizy ruchu i ulepszania serwisu.',
      'confirm-query-from-url-replace':
        'Jesteś zalogowany i masz zapisane wyszukiwanie. Czy chcesz je zmienić na wyszukiwanie z linku?',
      'substitute your query': 'zamień swoje wyszukiwanie',
      recent: 'bieżące',
      favorites: 'ulubione',
      'Please enter your username and password':
        'Podaj nazwę użytkownika oraz hasło',
      'subject of the advertisement': 'Przedmiot ogłoszenia',
      details: 'Szczegóły',
      photos: 'Zdjęcia',
      back: 'cofnij',
      next: 'dalej',
      finish: 'zakończ',
      title: 'tytuł',
      description: 'opis',
      street: 'ulica',
      'house number': 'nr',
      costs: 'koszty',
      'rental cost': 'koszty wynajmu',
      'additional monthly costs': 'media i dodatkowe koszty miesięczne',
      deposit: 'kaucja',
      'contact details': 'Dane kontaktowe',
      'invalid phone number':
        'dopuszczalny format +48 xxx xxx xxx lub z pominięciem nr kierunkowego kraju',
      capacity: 'dla ilu osób',
      'type house number in the next field': 'wpisz numer domu w polu obok',
      'accepts only letters': 'tylko litery',
      'accepts only numbers or combination of numbers and 1 letter':
        'tylko cyfry lub kombinacja cyfr i jednej litery',
      'must be greater or equal':
        'wartość musi być większa lub równa {{ threshold }}',
      'Identifier or password invalid.':
        'Nieprawidłowa nazwa użytkownika lub hasło',
      'place an add': 'dodaj ogłoszenie',
      capacity_one: 'jedna',
      capacity_two: 'dwie',
      capacity_couple: 'para',
      'general information': 'informacje ogólne',
      type_room: 'pokój',
      type_sharedRoom: 'miejsce w pokoju',
      month: 'miesiąc',
      'bills included': 'rachunki wliczone',
      availability: 'dostępność',
      to: 'do',
      see_photos_0: 'zobacz {{count}} zdjęcie',
      see_photos_1: 'zobacz {{count}} zdjęcia',
      see_photos_2: 'zobacz {{count}} zdjęć',
      pln: 'zł',
      'my ads': 'moje ogłoszenia',
      preview: 'podgląd',
      edit: 'edycja',
      'save as draft': 'zapisz szkic',
      publish: 'opublikuj',
      'Your ad has been published.': 'Twoje ogłoszenie zostało opublikowane',
      'Your ad has been saved as a draft.':
        'Twoje ogłoszenie zostało zapisane jako szkic',
      'invalid image type': 'błędny format pliku',
      drag_and_drop: 'upuść pliki lub kliknij aby dodać',
      'accepted formats': 'dopuszczalne formaty',
      'passwords do not match': 'hasła się różnią',
      'please fill up the form': 'wypełnij formularz',
      'logged out': 'wylogowano',
      'login first': 'zaloguj się aby kontynuować',
      'unknown error occurred': 'wystąpił nieznany problem',
      'authentication error': 'błąd uwierzytelnienia',
      'No filter set. Please contact administrator at':
        'Nie wybrano filtru wyszukiwania. Skontakuj się z administratorem pod adresem',
      'choose filter': 'wybierz filtr',
      'chosen filter': 'wybrany filtr'
    }
  },
  en: {
    translation: {
      rooms_number: 'rooms number',
      'ad-type': 'ad type',
      max_price: 'maximum price',
      query: 'set the query',
      results: 'see the results',
      location_placeholder:
        'street, district, a part of the city included in the description etc.',
      upto: 'up to',
      price: 'rent price',
      required: 'the field is required',
      numbers_only: 'numbers only',
      natural_numbers_only: 'natural numbers only',
      alpha_only: 'words only',
      at_least_one_required: 'at least one value is required',
      improper_range: 'invalid range',
      login: 'log in',
      register: 'register',
      username: 'email',
      password: 'password',
      password1: 'password',
      password2: 'repeat password',
      registration: 'new user registration',
      must_be_greater_than:
        'the value must be greater than the threshold {{ threshold }}',
      logout: 'log out',
      RentType0: 'room',
      RentType1: 'flat',
      RentType2: 'house',
      RentType4: 'any',
      krakow: 'Kraków',
      wroclaw: 'Wrocław',
      'account-activating-message':
        'The activation link is being checked. In a moment you will be forwarded.',
      'account-verification-message':
        'In order to activate your account please click the link in a message sent on your email address.',
      'account-activation-error-message': `The activation link, which you use is invalid. Please contact with us with ${CONTACT_EMAIL}.`,
      'successful-verification-message': 'Your account was correctly verified.',
      room_plural: 'rooms',
      privacyPolicy: (
        <>
          I accept
          <PrivacyPolicyLink
            className={'ml-1'}
            onClick={() => GA_EVENTS.registration.visitPrivacyPolicy()}
          >
            Privacy Policy
          </PrivacyPolicyLink>
        </>
      ),
      terms: (
        <>
          I accept
          <TermsOfServiceLink
            className={'ml-1'}
            onClick={() => GA_EVENTS.registration.visitTermsOfUse()}
          >
            Terms of Service
          </TermsOfServiceLink>
        </>
      ),
      // this is translated to empty string because username and email fields duplicate, and this error is already shown
      // at email field
      'A user with that username already exists.': '',
      activation_requested: `I want the service to be realised now and the subscription to be activated immediately
        after the order what will result in cancellation from the right to resign from the contract.`,
      must_not_contain: 'Cannot contain words',
      must_contain: 'Must contain words',
      'no keywords': 'no keywords set',
      'must be longer than': 'must be longer than {{threshold}}',
      'must have length equal':
        'must have length equal {{threshold}} characters',
      'codes length is equal': 'codes length is equal {{threshold}} characters',
      keywords_duplicated_singular:
        'word {{enumeration}} cannot be required and forbidden at the same time',
      keywords_duplicated_plural:
        'words {{enumeration}} cannot be required and forbidden at the same time',
      whitelisted_keyword_duplicated_singular:
        'word {{enumeration}} cannot be added twice',
      whitelisted_keyword_duplicated_plural:
        'words {{enumeration}} cannot be added twice',
      code: 'promo code',
      make_account_to_save:
        'Create an account to save the filters and to save the time on setting the query parameters at your next visit.',
      log_in_to_save:
        'Log in to save the filters and to save the time on setting the query parameters at your next visit.',
      room_short: 'rooms',
      warszawa: 'Warszawa',
      poznan: 'Poznań',
      gdansk: 'Gdańsk',
      landing_header1:
        'Exactly the offers you need no\u00A0matter where they appear',
      'cookie info':
        'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.',
      en: 'English',
      pl: 'Polski',
      'confirm-query-from-url-replace':
        'You are logged in and you have a saved query. Do you want to replace it with the one in a link?',
      'Please enter your username and password':
        'Please enter your username and password',
      'subject of the advertisement': 'subject of the advertisement',
      details: 'details',
      photos: 'photos',
      back: 'back',
      next: 'next',
      finish: 'finish',
      title: 'title',
      description: 'description',
      street: 'street',
      'house number': 'no.',
      costs: 'costs',
      'rental cost': 'rental cost',
      'additional monthly costs': 'additional monthly costs',
      deposit: 'deposit',
      'contact details': 'contact details',
      'invalid phone number':
        'accepted format: +48 XXX XXX XXX or without country code',
      capacity: 'capacity',
      'type house number in the next field':
        'type house number in the next field',
      'accepts only letters': 'accepts only letters',
      'accepts only numbers or combination of numbers and 1 letter':
        'accepts only numbers or combination of numbers and 1 letter',
      'must be greater or equal': 'must be greater or equal {{threshold}}',
      'place an add': 'place an add',
      capacity_1: 'one',
      capacity_2: 'two',
      capacity_pair: 'pair',
      'general information': 'general information',
      type_room: 'room',
      type_sharedRoom: 'shared room',
      'bills included': 'bills included',
      see_photos: 'see {{count}} photo',
      see_photos_plural: 'see {{count}} photos',
      pln: 'PLN',
      drag_and_drop: "Drag 'n drop some files here, or click to select them",
      'add note': 'dodaj notatkę',
      'edit note': 'edytuj notatkę',
      watchlist: 'obserwowane',
      watch: 'obserwuj',
      'stop watching': 'przestań obserwować',
      new: 'nowe',
      expired_url: 'wygasło',
      sold: 'sprzedane',
      presold: 'przedsprzedane',
      uninteresting: 'nieodpowiednie',
      visited: 'odwiedzone'
    }
  }
};

export const i18n: i18nBase.i18n = getI18n(translationResources);
