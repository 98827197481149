import React, { useCallback } from 'react';
import { PageContainer } from 'public/components/page-container';
import { Navbar } from 'app/shared/components/navbar';
import { Footer } from 'app/root/components/footer';
import { useCT } from 'public/hooks/translation';
import { useHistory } from 'public/routing/hooks/history';
import { Redirect } from 'public/auth/models/redirect';
import { Centered } from 'public/components/centered';
import { AuthenticationDialogContent } from 'public/auth/components/authentication-dialog-content';
import { useAuth } from 'public/auth/providers/auth-provider';

export function Login(): JSX.Element {
  const ct = useCT();
  const {
    goBack,
    push,
    location: { state }
  } = useHistory<Redirect, any>();
  const from = state && state.from ? state.from : '/';
  const { isAuthenticated } = useAuth();
  const closeButtonLabel =
    state && state.from ? ct('go back') : ct('go to the main page');
  const onSuccess = useCallback(() => {
    push(from, currentSearch => {
      const { from, ...rest } = currentSearch;
      return rest;
    });
  }, [push, from]);
  return (
    <PageContainer
      contentClassName={'flex-1'}
      navbar={<Navbar />}
      footer={<Footer />}
    >
      <Centered>
        <AuthenticationDialogContent
          enableOAuthProviders={false}
          close={goBack}
          confirm={onSuccess}
          closeButtonLabel={closeButtonLabel}
          showCloseButton={isAuthenticated}
        />
      </Centered>
    </PageContainer>
  );
}
