import './edit-profile.sass';
import React from 'react';
import { capitalizeFirstLetter } from 'public/util';
import { useT } from 'public/hooks/translation';
import { PageContainer } from 'public/components/page-container';
import { Navbar } from 'app/shared/components/navbar';
import { UserRequest } from 'public/auth/providers/user-provider';
import { WithClassName } from 'public/props';
import { merge } from 'public/css';

export function EditProfile({ className }: WithClassName): JSX.Element {
  const t = useT();
  const user = UserRequest.useData();

  return (
    <PageContainer
      contentClassName={merge('container edit-profile-root', className)}
      navbar={<Navbar />}
    >
      <h1>{capitalizeFirstLetter(t('your data'))}</h1>
      <section>
        <h2>{capitalizeFirstLetter(t('email'))}</h2>
        <p>{user.email}</p>
      </section>
    </PageContainer>
  );
}
