import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  BROWSER,
  PRIVACY_POLICY,
  PROFILE,
  TERMS_OF_SERVICE
} from '../models/urls';
import { Auth } from 'public/auth/auth';
import { AUTH, LOGIN } from 'public/auth/models/urls';
import { GA_EVENTS } from '../utils/google-analytics';
import { AdsBrowser } from 'app/ads-browser/ads-browser';
import { Profile } from 'app/profile/profile';
import { CookiesAcceptRequest } from 'branded/analytics/components/cookies-accept-request';
import { useAuth } from 'public/auth/providers/auth-provider';
import { Redirect } from 'public/routing/components/redirect';
import { AuthRoute } from 'public/auth/components/auth-route';
import { TermsOfService } from 'app/root/pages/terms-of-service';
import { PrivacyPolicy } from 'app/root/pages/privacy-policy';
import { useStylesFill } from 'public/styles';

export function App(): JSX.Element {
  const styles = useStylesFill();
  const { isAuthenticated } = useAuth();
  return (
    <>
      <Switch>
        <Route path='/' exact>
          {isAuthenticated ? (
            <Redirect to={BROWSER} />
          ) : (
            <Redirect to={LOGIN} />
          )}
        </Route>
        <Route path={AUTH}>
          <Auth />
        </Route>
        <Route path={TERMS_OF_SERVICE}>
          <TermsOfService />
        </Route>
        <Route path={PRIVACY_POLICY}>
          <PrivacyPolicy />
        </Route>
        <AuthRoute
          path={BROWSER}
          className={styles.fill}
          contentClassName={styles.fill}
        >
          {({ className }) => <AdsBrowser className={className} />}
        </AuthRoute>
        <AuthRoute
          path={PROFILE}
          className={styles.fill}
          contentClassName={styles.fill}
        >
          {({ className }) => <Profile className={className} />}
        </AuthRoute>
        <Redirect to={'/'} />
      </Switch>
      <CookiesAcceptRequest
        onAccept={() => GA_EVENTS.acquisition.cookiesAgreement()}
      />
    </>
  );
}
