import './ads-browser.sass';
import React from 'react';
import { Route, Switch } from 'react-router';
import { RESULTS, WATCHLIST } from '../root/models/urls';
import { Results } from './pages/results';
import { BottomNavigation } from './navigation/components/bottom-navigation';
import { Redirect } from 'public/routing/components/redirect';
import { Watchlist } from 'app/ads-browser/pages/watchlist';
import {
  FilterProvider,
  MyFiltersRequest
} from 'app/ads-browser/providers/filter-provider';
import { useNavigationVariant } from './navigation/hooks/useNavigationVariant';
import { makeStyles } from '@material-ui/core/styles';
import { merge } from '../../public/css';

interface Props {
  className?: string;
}

export const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
    width: '100%'
  },
  flex: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export function AdsBrowser({ className }: Props): JSX.Element {
  const { content, flex } = useStyles();
  const { isNarrow } = useNavigationVariant();
  return (
    <MyFiltersRequest.WrapperWithProvider
      className={className}
      contentClassName={content}
    >
      {({ className }) => (
        <div className={merge(className, flex)}>
          <FilterProvider>
            <Switch>
              <Route path={RESULTS} exact>
                <Results />
              </Route>
              <Route path={WATCHLIST} exact>
                <Watchlist />
              </Route>
              <Redirect to={RESULTS} />
            </Switch>
            {isNarrow && <BottomNavigation />}
          </FilterProvider>
        </div>
      )}
    </MyFiltersRequest.WrapperWithProvider>
  );
}
