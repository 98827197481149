import { MuiPickersUtilsProvider as MuiPickersUtilsProviderBase } from '@material-ui/pickers';
import { ProviderComponentProps } from 'public/components/provider-group';
import Utils from '@date-io/luxon';
import React from 'react';

export const MuiPickersUtilsProvider = ({
  children
}: ProviderComponentProps): JSX.Element => (
  <MuiPickersUtilsProviderBase utils={Utils} locale={'pl'}>
    {children}
  </MuiPickersUtilsProviderBase>
);
