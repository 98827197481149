import {
  ProviderComponent,
  ProviderComponentProps,
  ProviderGroup
} from 'public/components/provider-group';
import React from 'react';
import { ApolloProvider } from './apollo-provider';
import { ThemeProvider } from './theme-provider';
import { AuthProvider } from 'public/auth/providers/auth-provider';
import { DialogProvider } from 'public/dialogs/dialog-provider';
import { AnalyticsProvider } from 'branded/analytics/components/analytics-provider';
import { NotificationsProvider } from 'public/notifications/notifications-provider';
import { I18nextProvider as I18nextProviderBase } from 'react-i18next';
import { i18n } from 'app/i18n';
import { MuiPickersUtilsProvider } from './mui-pickers';
import { TokenProvider } from 'public/auth/providers/token-provider';
import { CompanyDetailsProvider } from 'branded/providers/company-details-provider';
import { Router } from 'public/providers/router';
import { AuthErrorBoundary } from 'public/auth/providers/login-redirect-provider';

const I18nextProvider = ({ children }: ProviderComponentProps) => (
  <I18nextProviderBase i18n={i18n}>{children}</I18nextProviderBase>
);

/**
 * Holds global state providers
 */
export const Providers: ProviderComponent = ({
  children
}: ProviderComponentProps): JSX.Element => {
  return (
    <ProviderGroup
      providers={[CompanyDetailsProvider, NotificationsProvider, Router]}
    >
      <AuthErrorBoundary>
        <ProviderGroup
          providers={[
            ThemeProvider,
            TokenProvider,
            I18nextProvider,
            ApolloProvider,
            AuthProvider,
            DialogProvider,
            AnalyticsProvider,
            MuiPickersUtilsProvider
          ]}
        >
          {children}
        </ProviderGroup>
      </AuthErrorBoundary>
    </ProviderGroup>
  );
};
