import React from 'react';
import { Logo } from 'branded/components/logo';
import { capitalizeFirstLetter } from 'public/util';
import './navbar.sass';
import { merge } from 'public/css';
import { PROFILE_EDIT } from 'app/root/models/urls';
import { AccountCircle } from '@material-ui/icons';
import { useT } from 'public/hooks/translation';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Hidden,
  IconButton,
  Toolbar,
  useMediaQuery
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import InfoIcon from '@material-ui/icons/Info';
import { MenuButton } from 'public/components/menu-button';
import { useAuth } from 'public/auth/providers/auth-provider';
import { Link } from 'public/routing/components/link';
import { Licenses } from 'branded/components/licences-modal';
import { Contact } from 'public/components/contact-modal';
import { AuthenticationDialog } from 'public/auth/components/authentication-dialog';
import { useCompanyDetails } from 'public/providers/company-details-provider';
import { useSimpleDialog } from 'public/dialogs/SimpleDialog';
import { useConfirmDialog } from 'public/dialogs/ConfirmDialog';

export interface Props {
  className?: string;
  showLogo?: boolean;
  children?: React.ReactNode;
}

export function Navbar({
  children,
  className,
  showLogo = true
}: Props): JSX.Element {
  const { isAuthenticated, logout } = useAuth();
  const t = useT();
  const { i18n } = useTranslation();
  const { open: openLicenses } = useSimpleDialog(Licenses);
  const { open: openContact } = useSimpleDialog(Contact);
  const { open: openAuthentication } = useConfirmDialog(AuthenticationDialog);
  const { TermsOfServiceLink, PrivacyPolicyLink } = useCompanyDetails();
  const enlargeLogo = useMediaQuery('(min-width:500px) and (min-height:750px)');

  return (
    <AppBar
      elevation={0}
      className={merge(className, 'navbar-root', !showLogo && 'no-logo')}
      position='static'
    >
      <Toolbar className={''}>
        {showLogo && (
          <Box flexGrow={1}>
            <Link to={'/'}>
              <Logo height={enlargeLogo ? 40 : 30} />
            </Link>
          </Box>
        )}
        {process.env.NODE_ENV === 'development' && (
          <div style={{ fontSize: '2em', fontWeight: 700 }}>DEVELOPMENT</div>
        )}
        {children}
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Box
            id={'language-menu'}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Button
              style={{ maxWidth: '35px', minWidth: '35px' }}
              color={i18n.language === 'en' ? 'primary' : 'inherit'}
              onClick={() => i18n.changeLanguage('en')}
            >
              {'en'.toUpperCase()}
            </Button>
            <div className='vLine' />
            <Button
              style={{ maxWidth: '35px', minWidth: '35px' }}
              color={i18n.language === 'pl' ? 'primary' : 'inherit'}
              onClick={() => i18n.changeLanguage('pl')}
            >
              {'pl'.toUpperCase()}
            </Button>
          </Box>
          <Hidden xsDown implementation='css'>
            <MenuButton
              button={props => (
                <IconButton {...props} color='inherit' aria-label={t('info')}>
                  <InfoIcon />
                </IconButton>
              )}
              id={'info-menu'}
            >
              {TermsOfServiceLink && (
                <MenuItem component={TermsOfServiceLink}>
                  {capitalizeFirstLetter(t('terms of service'))}
                </MenuItem>
              )}
              <MenuItem component={PrivacyPolicyLink}>
                {capitalizeFirstLetter(t('privacy policy'))}
              </MenuItem>
              <MenuItem onClick={() => openLicenses()}>
                {capitalizeFirstLetter(t('licenses'))}
              </MenuItem>
              <MenuItem onClick={() => openContact()}>
                {capitalizeFirstLetter(t('contact'))}
              </MenuItem>
            </MenuButton>
          </Hidden>
          {isAuthenticated ? (
            <MenuButton
              button={props => (
                <IconButton
                  {...props}
                  color='inherit'
                  aria-label={t('user account')}
                >
                  <AccountCircle />
                </IconButton>
              )}
              id={'user-menu'}
            >
              <MenuItem component={Link} to={PROFILE_EDIT}>
                {capitalizeFirstLetter(t('profile'))}
              </MenuItem>
              <MenuItem onClick={logout}>
                {capitalizeFirstLetter(t('logout'))}
              </MenuItem>
            </MenuButton>
          ) : (
            <Button color={'inherit'} onClick={() => openAuthentication()}>
              {capitalizeFirstLetter(t('login'))}
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
