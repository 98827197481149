import {
  AdFragmentFragment,
  useWatchlistQuery,
  WatchlistQuery
} from 'generated/graphql';
import { isNotNull, isNull } from 'public/util';
import { createPaginationRequestTools } from 'public/requests/request-wrapper/pagination-request-tools';

export const WatchlistData = createPaginationRequestTools<
  WatchlistQuery,
  AdFragmentFragment[]
>({
  useRequest: useWatchlistQuery,
  extractData: (payload: WatchlistQuery): AdFragmentFragment[] =>
    isNull(payload.myWatchlist) ? [] : payload.myWatchlist.filter(isNotNull),
  displayName: 'watchlist'
});
