import { ContextHookFactory } from 'public/utils/context-hook';
import { Filter } from 'public/auth/models/user';
import { ProviderComponentProps } from 'public/components/provider-group';
import { UserRequest } from 'public/auth/providers/user-provider';
import { useCT } from 'public/hooks/translation';
import { useCompanyDetails } from 'public/providers/company-details-provider';
import { isNotNull, isNull } from 'public/util';
import { PageContainer } from 'public/components/page-container';
import { Navbar } from 'app/shared/components/navbar';
import { DefaultErrorPlaceholder } from 'public/requests/components/error-placeholder';
import React, { useCallback, useMemo } from 'react';
import { Map } from 'immutable';
import { createRequestTools } from 'public/requests/request-wrapper/request-tools';
import {
  MyFiltersQuery,
  useMyFiltersQuery as useMyFiltersQueryBase,
  useSetFilterMutation
} from 'generated/graphql';
import { extractData } from 'public/graphql/tools';

export interface ContextProps {
  current: Filter;
  available: Filter[];
  getName: (id: Filter['id']) => string | null;
  setFilter: (val: Filter['id']) => Promise<void>;
}

export const FilterApiContext =
  ContextHookFactory.createContext<ContextProps>('filter api');

export const useFilterApi =
  ContextHookFactory.createHook<ContextProps>(FilterApiContext);

const useMyFiltersQuery = extractData(
  useMyFiltersQueryBase,
  (response: MyFiltersQuery) =>
    response.filtersMine ? response.filtersMine.filter(isNotNull) : []
);

export const MyFiltersRequest = createRequestTools<Filter[]>({
  useRequest: useMyFiltersQuery,
  displayName: 'my filters'
});

export function FilterProvider({
  children
}: ProviderComponentProps): JSX.Element {
  const { trigger: setFilter } = useSetFilterMutation();
  const { currentFilter: storedFilter } = UserRequest.useData();
  const available = MyFiltersRequest.useData();
  const ct = useCT();
  const { contactEmail } = useCompanyDetails();
  const filterById = useMemo(
    () =>
      Map(
        available.reduce(
          (acc: Array<[Filter['id'], Filter]>, val: Filter) =>
            [...acc, [val.id, val]] as Array<[Filter['id'], Filter]>,
          []
        )
      ),
    [available]
  );

  const getName = useCallback(
    id => {
      const found = filterById.get(id);
      if (!found) {
        return null;
      }
      return found.name;
    },
    [filterById]
  );
  const currentFilter = storedFilter || available[0] || null;

  if (isNull(currentFilter)) {
    return (
      <PageContainer
        className={'results-root'}
        contentClassName={'flex-1'}
        navbar={<Navbar />}
      >
        <DefaultErrorPlaceholder
          value={{
            messages: [
              `${ct(
                'No filter set. Please contact administrator at'
              )} ${contactEmail}`
            ]
          }}
        />
      </PageContainer>
    );
  }
  return (
    <FilterApiContext.Provider
      value={{
        current: currentFilter,
        available,
        getName,
        setFilter: val => setFilter({ variables: { filterId: val } }).then()
      }}
    >
      {children}
    </FilterApiContext.Provider>
  );
}
