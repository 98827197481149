import {
  AdFragmentFragment,
  AdsQuery,
  AdsQueryVariables,
  useAdsQuery as useAdsQueryBase
} from 'generated/graphql';
import { isNotNull, isNull } from 'public/util';
import {
  createPaginationRequestTools,
  PaginationRequestHookOptions
} from 'public/requests/request-wrapper/pagination-request-tools';
import { useFilterApi } from 'app/ads-browser/providers/filter-provider';
import * as Apollo from 'public/graphql/apollo-custom';

export function useAdsQuery(
  baseOptions: PaginationRequestHookOptions<AdsQuery>
) {
  const {
    current: { area, pricePerSquareMeter, size, price }
  } = useFilterApi();
  const { variables: baseVariables, ...rest } = baseOptions;
  const variables: AdsQueryVariables = {
    ...baseVariables,
    area,
    maxPricePerSquareMeter: pricePerSquareMeter?.max,
    minPricePerSquareMeter: pricePerSquareMeter?.min,
    maxSize: size?.max,
    minSize: size?.min,
    maxPrice: price?.max,
    minPrice: price?.min
  };
  return useAdsQueryBase({
    ...(rest as Apollo.QueryHookOptions<AdsQuery, AdsQueryVariables>),
    variables: variables
  });
}

export const ResultsData = createPaginationRequestTools<
  AdsQuery,
  AdFragmentFragment[]
>({
  useRequest: useAdsQuery,
  extractData: (payload: AdsQuery): AdFragmentFragment[] =>
    isNull(payload.ads) ? [] : payload.ads.filter(isNotNull),
  displayName: 'results'
});
