import React from 'react';
import { useCT } from 'public/hooks/translation';
import { capitalizeFirstLetter } from 'public/util';
import { InputField } from 'public/forms/fields/input-field';
import { Offer } from 'app/ads-browser/models/offer';
import { FormDialog, FormDialogBaseProps } from 'public/dialogs';
import {
  SimpleDialogProps,
  useSimpleDialog
} from 'public/dialogs/SimpleDialog';
import { AdMutationsWrapper, AdContext } from 'app/ads-browser/api/ad';

// formName is set inside the component
export type BaseProps = Omit<FormDialogBaseProps<Offer>, 'formName'>;
export interface Props extends SimpleDialogProps {
  data: Offer;
}

export function AddNoteDialog({ data, ...dialogProps }: Props): JSX.Element {
  const ct = useCT();
  return (
    <AdContext.Provider value={data}>
      <AdMutationsWrapper>
        {({ setNote }) => (
          <FormDialog<Offer>
            {...dialogProps}
            initialValues={data}
            confirm={(data: Offer) => {
              setNote(data['note'] || '').then();
              dialogProps.close();
            }}
            DialogTitleContent={ct('add note')}
            formName={'add-note'}
          >
            <InputField
              fullWidth
              multiline
              variant={'filled'}
              name={'note'}
              rows={10}
              label={capitalizeFirstLetter(ct('note'))}
            />
          </FormDialog>
        )}
      </AdMutationsWrapper>
    </AdContext.Provider>
  );
}

export const useAddNoteDialog = (data: Offer) =>
  useSimpleDialog((dialogProps: SimpleDialogProps) => (
    <AddNoteDialog {...dialogProps} data={data} />
  ));
