import { DateTime } from 'luxon';
import { gql } from '@apollo/client';
import * as Apollo from 'public/graphql/apollo-custom';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: DateTime;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: DateTime;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Long` scalar type represents 52-bit integers */
  Long: any;
  /** A time string with format: HH:mm:ss.SSS */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Ad = {
  __typename?: 'Ad';
  _id: Scalars['ID'];
  adType: Maybe<Enum_Ad_Adtype>;
  addedAt: Maybe<Scalars['DateTime']>;
  area: Maybe<Scalars['String']>;
  areatags: Maybe<Array<Maybe<Areatag>>>;
  city: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Maybe<Array<Maybe<UploadFile>>>;
  incomplete: Maybe<Scalars['Boolean']>;
  isWatched: Maybe<Scalars['Boolean']>;
  maintenanceMonthlyCost: Maybe<Scalars['Int']>;
  note: Maybe<Scalars['String']>;
  offerDigest: Maybe<Scalars['String']>;
  offerObject: Maybe<Enum_Ad_Offerobject>;
  price: Maybe<Scalars['Int']>;
  pricePerSquareMeter: Maybe<Scalars['Int']>;
  rooms: Maybe<Scalars['Int']>;
  size: Maybe<Scalars['Int']>;
  sourceUrl: Maybe<Scalars['String']>;
  state: Maybe<Enum_Ad_State>;
  title: Maybe<Scalars['String']>;
  type: Maybe<Enum_Ad_Type>;
  updated_at: Scalars['DateTime'];
  urls: Maybe<Array<Maybe<Urls>>>;
};

export type AdAreatagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type AdImagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type AdUrlsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type AdAggregator = {
  __typename?: 'AdAggregator';
  avg: Maybe<AdAggregatorAvg>;
  count: Maybe<Scalars['Int']>;
  max: Maybe<AdAggregatorMax>;
  min: Maybe<AdAggregatorMin>;
  sum: Maybe<AdAggregatorSum>;
  totalCount: Maybe<Scalars['Int']>;
};

export type AdAggregatorAvg = {
  __typename?: 'AdAggregatorAvg';
  maintenanceMonthlyCost: Maybe<Scalars['Float']>;
  price: Maybe<Scalars['Float']>;
  pricePerSquareMeter: Maybe<Scalars['Float']>;
  rooms: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
};

export type AdAggregatorMax = {
  __typename?: 'AdAggregatorMax';
  maintenanceMonthlyCost: Maybe<Scalars['Float']>;
  price: Maybe<Scalars['Float']>;
  pricePerSquareMeter: Maybe<Scalars['Float']>;
  rooms: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
};

export type AdAggregatorMin = {
  __typename?: 'AdAggregatorMin';
  maintenanceMonthlyCost: Maybe<Scalars['Float']>;
  price: Maybe<Scalars['Float']>;
  pricePerSquareMeter: Maybe<Scalars['Float']>;
  rooms: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
};

export type AdAggregatorSum = {
  __typename?: 'AdAggregatorSum';
  maintenanceMonthlyCost: Maybe<Scalars['Float']>;
  price: Maybe<Scalars['Float']>;
  pricePerSquareMeter: Maybe<Scalars['Float']>;
  rooms: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
};

export type AdConnection = {
  __typename?: 'AdConnection';
  aggregate: Maybe<AdAggregator>;
  groupBy: Maybe<AdGroupBy>;
  values: Maybe<Array<Maybe<Ad>>>;
};

export type AdConnectionAdType = {
  __typename?: 'AdConnectionAdType';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionAddedAt = {
  __typename?: 'AdConnectionAddedAt';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type AdConnectionArea = {
  __typename?: 'AdConnectionArea';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionCity = {
  __typename?: 'AdConnectionCity';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionCreated_At = {
  __typename?: 'AdConnectionCreated_at';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type AdConnectionDescription = {
  __typename?: 'AdConnectionDescription';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionId = {
  __typename?: 'AdConnectionId';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['ID']>;
};

export type AdConnectionIncomplete = {
  __typename?: 'AdConnectionIncomplete';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['Boolean']>;
};

export type AdConnectionIsWatched = {
  __typename?: 'AdConnectionIsWatched';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['Boolean']>;
};

export type AdConnectionMaintenanceMonthlyCost = {
  __typename?: 'AdConnectionMaintenanceMonthlyCost';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['Int']>;
};

export type AdConnectionNote = {
  __typename?: 'AdConnectionNote';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionOfferDigest = {
  __typename?: 'AdConnectionOfferDigest';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionOfferObject = {
  __typename?: 'AdConnectionOfferObject';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionPrice = {
  __typename?: 'AdConnectionPrice';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['Int']>;
};

export type AdConnectionPricePerSquareMeter = {
  __typename?: 'AdConnectionPricePerSquareMeter';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['Int']>;
};

export type AdConnectionRooms = {
  __typename?: 'AdConnectionRooms';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['Int']>;
};

export type AdConnectionSize = {
  __typename?: 'AdConnectionSize';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['Int']>;
};

export type AdConnectionSourceUrl = {
  __typename?: 'AdConnectionSourceUrl';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionState = {
  __typename?: 'AdConnectionState';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionTitle = {
  __typename?: 'AdConnectionTitle';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionType = {
  __typename?: 'AdConnectionType';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdConnectionUpdated_At = {
  __typename?: 'AdConnectionUpdated_at';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type AdConnection_Id = {
  __typename?: 'AdConnection_id';
  connection: Maybe<AdConnection>;
  key: Maybe<Scalars['ID']>;
};

export type AdGroupBy = {
  __typename?: 'AdGroupBy';
  _id: Maybe<Array<Maybe<AdConnection_Id>>>;
  adType: Maybe<Array<Maybe<AdConnectionAdType>>>;
  addedAt: Maybe<Array<Maybe<AdConnectionAddedAt>>>;
  area: Maybe<Array<Maybe<AdConnectionArea>>>;
  city: Maybe<Array<Maybe<AdConnectionCity>>>;
  created_at: Maybe<Array<Maybe<AdConnectionCreated_At>>>;
  description: Maybe<Array<Maybe<AdConnectionDescription>>>;
  id: Maybe<Array<Maybe<AdConnectionId>>>;
  incomplete: Maybe<Array<Maybe<AdConnectionIncomplete>>>;
  isWatched: Maybe<Array<Maybe<AdConnectionIsWatched>>>;
  maintenanceMonthlyCost: Maybe<
    Array<Maybe<AdConnectionMaintenanceMonthlyCost>>
  >;
  note: Maybe<Array<Maybe<AdConnectionNote>>>;
  offerDigest: Maybe<Array<Maybe<AdConnectionOfferDigest>>>;
  offerObject: Maybe<Array<Maybe<AdConnectionOfferObject>>>;
  price: Maybe<Array<Maybe<AdConnectionPrice>>>;
  pricePerSquareMeter: Maybe<Array<Maybe<AdConnectionPricePerSquareMeter>>>;
  rooms: Maybe<Array<Maybe<AdConnectionRooms>>>;
  size: Maybe<Array<Maybe<AdConnectionSize>>>;
  sourceUrl: Maybe<Array<Maybe<AdConnectionSourceUrl>>>;
  state: Maybe<Array<Maybe<AdConnectionState>>>;
  title: Maybe<Array<Maybe<AdConnectionTitle>>>;
  type: Maybe<Array<Maybe<AdConnectionType>>>;
  updated_at: Maybe<Array<Maybe<AdConnectionUpdated_At>>>;
};

export type AdInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  adType?: InputMaybe<Enum_Ad_Adtype>;
  addedAt?: InputMaybe<Scalars['DateTime']>;
  area?: InputMaybe<Scalars['String']>;
  areatags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  city?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  incomplete?: InputMaybe<Scalars['Boolean']>;
  isWatched?: InputMaybe<Scalars['Boolean']>;
  maintenanceMonthlyCost?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  offerDigest?: InputMaybe<Scalars['String']>;
  offerObject?: InputMaybe<Enum_Ad_Offerobject>;
  price?: InputMaybe<Scalars['Int']>;
  pricePerSquareMeter?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  rooms?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Enum_Ad_State>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_Ad_Type>;
  updated_by?: InputMaybe<Scalars['ID']>;
  urls?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type AdUserData = {
  __typename?: 'AdUserData';
  _id: Scalars['ID'];
  ad: Maybe<Ad>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isWatched: Maybe<Scalars['Boolean']>;
  note: Maybe<Scalars['String']>;
  state: Maybe<Enum_Aduserdata_State>;
  updatedAt: Scalars['DateTime'];
  user: Maybe<UsersPermissionsUser>;
};

export type AdUserDataAggregator = {
  __typename?: 'AdUserDataAggregator';
  count: Maybe<Scalars['Int']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type AdUserDataConnection = {
  __typename?: 'AdUserDataConnection';
  aggregate: Maybe<AdUserDataAggregator>;
  groupBy: Maybe<AdUserDataGroupBy>;
  values: Maybe<Array<Maybe<AdUserData>>>;
};

export type AdUserDataConnectionAd = {
  __typename?: 'AdUserDataConnectionAd';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['ID']>;
};

export type AdUserDataConnectionCreatedAt = {
  __typename?: 'AdUserDataConnectionCreatedAt';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type AdUserDataConnectionId = {
  __typename?: 'AdUserDataConnectionId';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['ID']>;
};

export type AdUserDataConnectionIsWatched = {
  __typename?: 'AdUserDataConnectionIsWatched';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['Boolean']>;
};

export type AdUserDataConnectionNote = {
  __typename?: 'AdUserDataConnectionNote';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdUserDataConnectionState = {
  __typename?: 'AdUserDataConnectionState';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['String']>;
};

export type AdUserDataConnectionUpdatedAt = {
  __typename?: 'AdUserDataConnectionUpdatedAt';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type AdUserDataConnectionUser = {
  __typename?: 'AdUserDataConnectionUser';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['ID']>;
};

export type AdUserDataConnection_Id = {
  __typename?: 'AdUserDataConnection_id';
  connection: Maybe<AdUserDataConnection>;
  key: Maybe<Scalars['ID']>;
};

export type AdUserDataGroupBy = {
  __typename?: 'AdUserDataGroupBy';
  _id: Maybe<Array<Maybe<AdUserDataConnection_Id>>>;
  ad: Maybe<Array<Maybe<AdUserDataConnectionAd>>>;
  createdAt: Maybe<Array<Maybe<AdUserDataConnectionCreatedAt>>>;
  id: Maybe<Array<Maybe<AdUserDataConnectionId>>>;
  isWatched: Maybe<Array<Maybe<AdUserDataConnectionIsWatched>>>;
  note: Maybe<Array<Maybe<AdUserDataConnectionNote>>>;
  state: Maybe<Array<Maybe<AdUserDataConnectionState>>>;
  updatedAt: Maybe<Array<Maybe<AdUserDataConnectionUpdatedAt>>>;
  user: Maybe<Array<Maybe<AdUserDataConnectionUser>>>;
};

export type AdUserDatumInput = {
  ad?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  isWatched?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Enum_Aduserdata_State>;
  updated_by?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  firstname: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  username: Maybe<Scalars['String']>;
};

export type Areatag = {
  __typename?: 'Areatag';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AreatagAggregator = {
  __typename?: 'AreatagAggregator';
  count: Maybe<Scalars['Int']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type AreatagConnection = {
  __typename?: 'AreatagConnection';
  aggregate: Maybe<AreatagAggregator>;
  groupBy: Maybe<AreatagGroupBy>;
  values: Maybe<Array<Maybe<Areatag>>>;
};

export type AreatagConnectionCreatedAt = {
  __typename?: 'AreatagConnectionCreatedAt';
  connection: Maybe<AreatagConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type AreatagConnectionId = {
  __typename?: 'AreatagConnectionId';
  connection: Maybe<AreatagConnection>;
  key: Maybe<Scalars['ID']>;
};

export type AreatagConnectionName = {
  __typename?: 'AreatagConnectionName';
  connection: Maybe<AreatagConnection>;
  key: Maybe<Scalars['String']>;
};

export type AreatagConnectionUpdatedAt = {
  __typename?: 'AreatagConnectionUpdatedAt';
  connection: Maybe<AreatagConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type AreatagConnection_Id = {
  __typename?: 'AreatagConnection_id';
  connection: Maybe<AreatagConnection>;
  key: Maybe<Scalars['ID']>;
};

export type AreatagGroupBy = {
  __typename?: 'AreatagGroupBy';
  _id: Maybe<Array<Maybe<AreatagConnection_Id>>>;
  createdAt: Maybe<Array<Maybe<AreatagConnectionCreatedAt>>>;
  id: Maybe<Array<Maybe<AreatagConnectionId>>>;
  name: Maybe<Array<Maybe<AreatagConnectionName>>>;
  updatedAt: Maybe<Array<Maybe<AreatagConnectionUpdatedAt>>>;
};

export type AreatagInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type ComponentGeneralRange = {
  __typename?: 'ComponentGeneralRange';
  _id: Scalars['ID'];
  id: Scalars['ID'];
  max: Maybe<Scalars['Int']>;
  min: Maybe<Scalars['Int']>;
};

export type ComponentGeneralRangeInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type Enum_Aduserdata_State =
  | 'expired_url'
  | 'new'
  | 'presold'
  | 'sold'
  | 'uninteresting'
  | 'visited';

export type Enum_Ad_Adtype = 'agency' | 'private';

export type Enum_Ad_Offerobject = 'flat' | 'plot';

export type Enum_Ad_State =
  | 'expired_url'
  | 'new'
  | 'presold'
  | 'sold'
  | 'uninteresting'
  | 'visited';

export type Enum_Ad_Type = 'rent' | 'sell';

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  ext?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: InputMaybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  related?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  size: Scalars['Float'];
  updated_by?: InputMaybe<Scalars['ID']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type Filter = {
  __typename?: 'Filter';
  _id: Scalars['ID'];
  area: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  owner: Maybe<UsersPermissionsUser>;
  price: Maybe<ComponentGeneralRange>;
  pricePerSquareMeter: Maybe<ComponentGeneralRange>;
  published_at: Maybe<Scalars['DateTime']>;
  size: Maybe<ComponentGeneralRange>;
  updatedAt: Scalars['DateTime'];
};

export type FilterAggregator = {
  __typename?: 'FilterAggregator';
  count: Maybe<Scalars['Int']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type FilterConnection = {
  __typename?: 'FilterConnection';
  aggregate: Maybe<FilterAggregator>;
  groupBy: Maybe<FilterGroupBy>;
  values: Maybe<Array<Maybe<Filter>>>;
};

export type FilterConnectionArea = {
  __typename?: 'FilterConnectionArea';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['String']>;
};

export type FilterConnectionCreatedAt = {
  __typename?: 'FilterConnectionCreatedAt';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type FilterConnectionId = {
  __typename?: 'FilterConnectionId';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['ID']>;
};

export type FilterConnectionName = {
  __typename?: 'FilterConnectionName';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['String']>;
};

export type FilterConnectionOwner = {
  __typename?: 'FilterConnectionOwner';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['ID']>;
};

export type FilterConnectionPrice = {
  __typename?: 'FilterConnectionPrice';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['ID']>;
};

export type FilterConnectionPricePerSquareMeter = {
  __typename?: 'FilterConnectionPricePerSquareMeter';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['ID']>;
};

export type FilterConnectionPublished_At = {
  __typename?: 'FilterConnectionPublished_at';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type FilterConnectionSize = {
  __typename?: 'FilterConnectionSize';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['ID']>;
};

export type FilterConnectionUpdatedAt = {
  __typename?: 'FilterConnectionUpdatedAt';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type FilterConnection_Id = {
  __typename?: 'FilterConnection_id';
  connection: Maybe<FilterConnection>;
  key: Maybe<Scalars['ID']>;
};

export type FilterGroupBy = {
  __typename?: 'FilterGroupBy';
  _id: Maybe<Array<Maybe<FilterConnection_Id>>>;
  area: Maybe<Array<Maybe<FilterConnectionArea>>>;
  createdAt: Maybe<Array<Maybe<FilterConnectionCreatedAt>>>;
  id: Maybe<Array<Maybe<FilterConnectionId>>>;
  name: Maybe<Array<Maybe<FilterConnectionName>>>;
  owner: Maybe<Array<Maybe<FilterConnectionOwner>>>;
  price: Maybe<Array<Maybe<FilterConnectionPrice>>>;
  pricePerSquareMeter: Maybe<Array<Maybe<FilterConnectionPricePerSquareMeter>>>;
  published_at: Maybe<Array<Maybe<FilterConnectionPublished_At>>>;
  size: Maybe<Array<Maybe<FilterConnectionSize>>>;
  updatedAt: Maybe<Array<Maybe<FilterConnectionUpdatedAt>>>;
};

export type FilterInput = {
  area: Scalars['String'];
  created_by?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  owner?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<ComponentGeneralRangeInput>;
  pricePerSquareMeter?: InputMaybe<ComponentGeneralRangeInput>;
  published_at?: InputMaybe<Scalars['DateTime']>;
  size?: InputMaybe<ComponentGeneralRangeInput>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type InputId = {
  id: Scalars['ID'];
};

export type Morph =
  | Ad
  | AdAggregator
  | AdAggregatorAvg
  | AdAggregatorMax
  | AdAggregatorMin
  | AdAggregatorSum
  | AdConnection
  | AdConnectionAdType
  | AdConnectionAddedAt
  | AdConnectionArea
  | AdConnectionCity
  | AdConnectionCreated_At
  | AdConnectionDescription
  | AdConnectionId
  | AdConnectionIncomplete
  | AdConnectionIsWatched
  | AdConnectionMaintenanceMonthlyCost
  | AdConnectionNote
  | AdConnectionOfferDigest
  | AdConnectionOfferObject
  | AdConnectionPrice
  | AdConnectionPricePerSquareMeter
  | AdConnectionRooms
  | AdConnectionSize
  | AdConnectionSourceUrl
  | AdConnectionState
  | AdConnectionTitle
  | AdConnectionType
  | AdConnectionUpdated_At
  | AdConnection_Id
  | AdGroupBy
  | AdUserData
  | AdUserDataAggregator
  | AdUserDataConnection
  | AdUserDataConnectionAd
  | AdUserDataConnectionCreatedAt
  | AdUserDataConnectionId
  | AdUserDataConnectionIsWatched
  | AdUserDataConnectionNote
  | AdUserDataConnectionState
  | AdUserDataConnectionUpdatedAt
  | AdUserDataConnectionUser
  | AdUserDataConnection_Id
  | AdUserDataGroupBy
  | Areatag
  | AreatagAggregator
  | AreatagConnection
  | AreatagConnectionCreatedAt
  | AreatagConnectionId
  | AreatagConnectionName
  | AreatagConnectionUpdatedAt
  | AreatagConnection_Id
  | AreatagGroupBy
  | ComponentGeneralRange
  | Filter
  | FilterAggregator
  | FilterConnection
  | FilterConnectionArea
  | FilterConnectionCreatedAt
  | FilterConnectionId
  | FilterConnectionName
  | FilterConnectionOwner
  | FilterConnectionPrice
  | FilterConnectionPricePerSquareMeter
  | FilterConnectionPublished_At
  | FilterConnectionSize
  | FilterConnectionUpdatedAt
  | FilterConnection_Id
  | FilterGroupBy
  | Order
  | OrderAggregator
  | OrderConnection
  | OrderConnectionClient
  | OrderConnectionCreatedAt
  | OrderConnectionId
  | OrderConnectionItemPrototype
  | OrderConnectionUpdatedAt
  | OrderConnectionUrl
  | OrderConnection_Id
  | OrderGroupBy
  | UploadFile
  | UploadFileAggregator
  | UploadFileAggregatorAvg
  | UploadFileAggregatorMax
  | UploadFileAggregatorMin
  | UploadFileAggregatorSum
  | UploadFileConnection
  | UploadFileConnectionAlternativeText
  | UploadFileConnectionCaption
  | UploadFileConnectionCreatedAt
  | UploadFileConnectionExt
  | UploadFileConnectionFormats
  | UploadFileConnectionHash
  | UploadFileConnectionHeight
  | UploadFileConnectionId
  | UploadFileConnectionMime
  | UploadFileConnectionName
  | UploadFileConnectionPreviewUrl
  | UploadFileConnectionProvider
  | UploadFileConnectionProvider_Metadata
  | UploadFileConnectionSize
  | UploadFileConnectionUpdatedAt
  | UploadFileConnectionUrl
  | UploadFileConnectionWidth
  | UploadFileConnection_Id
  | UploadFileGroupBy
  | Urls
  | UrlsAggregator
  | UrlsConnection
  | UrlsConnectionAd
  | UrlsConnectionCreatedAt
  | UrlsConnectionId
  | UrlsConnectionPublished_At
  | UrlsConnectionSource
  | UrlsConnectionUpdatedAt
  | UrlsConnectionUrl
  | UrlsConnection_Id
  | UrlsGroupBy
  | UserPermissionsPasswordPayload
  | UsersPermissionsLoginPayload
  | UsersPermissionsMe
  | UsersPermissionsMeRole
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsRoleAggregator
  | UsersPermissionsRoleConnection
  | UsersPermissionsRoleConnectionDescription
  | UsersPermissionsRoleConnectionId
  | UsersPermissionsRoleConnectionName
  | UsersPermissionsRoleConnectionType
  | UsersPermissionsRoleConnection_Id
  | UsersPermissionsRoleGroupBy
  | UsersPermissionsUser
  | UsersPermissionsUserAggregator
  | UsersPermissionsUserConnection
  | UsersPermissionsUserConnectionBlocked
  | UsersPermissionsUserConnectionConfirmed
  | UsersPermissionsUserConnectionCreatedAt
  | UsersPermissionsUserConnectionCurrentFilter
  | UsersPermissionsUserConnectionEmail
  | UsersPermissionsUserConnectionId
  | UsersPermissionsUserConnectionProvider
  | UsersPermissionsUserConnectionRole
  | UsersPermissionsUserConnectionUpdatedAt
  | UsersPermissionsUserConnectionUsername
  | UsersPermissionsUserConnection_Id
  | UsersPermissionsUserGroupBy
  | CreateAdPayload
  | CreateAdUserDatumPayload
  | CreateAreatagPayload
  | CreateFilterPayload
  | CreateOrderPayload
  | CreateRolePayload
  | CreateUrlPayload
  | CreateUserPayload
  | DeleteAdPayload
  | DeleteAdUserDatumPayload
  | DeleteAreatagPayload
  | DeleteFilePayload
  | DeleteFilterPayload
  | DeleteOrderPayload
  | DeleteRolePayload
  | DeleteUrlPayload
  | DeleteUserPayload
  | UpdateAdPayload
  | UpdateAdUserDatumPayload
  | UpdateAreatagPayload
  | UpdateFilterPayload
  | UpdateOrderPayload
  | UpdateRolePayload
  | UpdateUrlPayload
  | UpdateUserPayload;

export type Mutation = {
  __typename?: 'Mutation';
  addToWatchlist: Ad;
  createAd: Maybe<CreateAdPayload>;
  createAdUserDatum: Maybe<CreateAdUserDatumPayload>;
  createAreatag: Maybe<CreateAreatagPayload>;
  createFilter: Maybe<CreateFilterPayload>;
  createOrder: Maybe<CreateOrderPayload>;
  /** Create a new role */
  createRole: Maybe<CreateRolePayload>;
  createUrl: Maybe<CreateUrlPayload>;
  /** Create a new user */
  createUser: Maybe<CreateUserPayload>;
  deleteAd: Maybe<DeleteAdPayload>;
  deleteAdUserDatum: Maybe<DeleteAdUserDatumPayload>;
  deleteAreatag: Maybe<DeleteAreatagPayload>;
  /** Delete one file */
  deleteFile: Maybe<DeleteFilePayload>;
  deleteFilter: Maybe<DeleteFilterPayload>;
  deleteOrder: Maybe<DeleteOrderPayload>;
  /** Delete an existing role */
  deleteRole: Maybe<DeleteRolePayload>;
  deleteUrl: Maybe<DeleteUrlPayload>;
  /** Delete an existing user */
  deleteUser: Maybe<DeleteUserPayload>;
  emailConfirmation: Maybe<UsersPermissionsLoginPayload>;
  forgotPassword: Maybe<UserPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFile>>;
  register: UsersPermissionsLoginPayload;
  removeFromWatchlist: Ad;
  resetPassword: Maybe<UsersPermissionsLoginPayload>;
  setFilter: Maybe<UsersPermissionsMe>;
  updateAd: Maybe<UpdateAdPayload>;
  updateAdUserDatum: Maybe<UpdateAdUserDatumPayload>;
  updateAreatag: Maybe<UpdateAreatagPayload>;
  updateFileInfo: UploadFile;
  updateFilter: Maybe<UpdateFilterPayload>;
  updateOrder: Maybe<UpdateOrderPayload>;
  /** Update an existing role */
  updateRole: Maybe<UpdateRolePayload>;
  updateUrl: Maybe<UpdateUrlPayload>;
  /** Update an existing user */
  updateUser: Maybe<UpdateUserPayload>;
  upload: UploadFile;
};

export type MutationAddToWatchlistArgs = {
  id: Scalars['ID'];
};

export type MutationCreateAdArgs = {
  input?: InputMaybe<CreateAdInput>;
};

export type MutationCreateAdUserDatumArgs = {
  input?: InputMaybe<CreateAdUserDatumInput>;
};

export type MutationCreateAreatagArgs = {
  input?: InputMaybe<CreateAreatagInput>;
};

export type MutationCreateFilterArgs = {
  input?: InputMaybe<CreateFilterInput>;
};

export type MutationCreateOrderArgs = {
  input?: InputMaybe<CreateOrderInput>;
};

export type MutationCreateRoleArgs = {
  input?: InputMaybe<CreateRoleInput>;
};

export type MutationCreateUrlArgs = {
  input?: InputMaybe<CreateUrlInput>;
};

export type MutationCreateUserArgs = {
  input?: InputMaybe<CreateUserInput>;
};

export type MutationDeleteAdArgs = {
  input?: InputMaybe<DeleteAdInput>;
};

export type MutationDeleteAdUserDatumArgs = {
  input?: InputMaybe<DeleteAdUserDatumInput>;
};

export type MutationDeleteAreatagArgs = {
  input?: InputMaybe<DeleteAreatagInput>;
};

export type MutationDeleteFileArgs = {
  input?: InputMaybe<DeleteFileInput>;
};

export type MutationDeleteFilterArgs = {
  input?: InputMaybe<DeleteFilterInput>;
};

export type MutationDeleteOrderArgs = {
  input?: InputMaybe<DeleteOrderInput>;
};

export type MutationDeleteRoleArgs = {
  input?: InputMaybe<DeleteRoleInput>;
};

export type MutationDeleteUrlArgs = {
  input?: InputMaybe<DeleteUrlInput>;
};

export type MutationDeleteUserArgs = {
  input?: InputMaybe<DeleteUserInput>;
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFromWatchlistArgs = {
  id: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type MutationSetFilterArgs = {
  filterId: Scalars['ID'];
};

export type MutationUpdateAdArgs = {
  input?: InputMaybe<UpdateAdInput>;
};

export type MutationUpdateAdUserDatumArgs = {
  input?: InputMaybe<UpdateAdUserDatumInput>;
};

export type MutationUpdateAreatagArgs = {
  input?: InputMaybe<UpdateAreatagInput>;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info: FileInfoInput;
};

export type MutationUpdateFilterArgs = {
  input?: InputMaybe<UpdateFilterInput>;
};

export type MutationUpdateOrderArgs = {
  input?: InputMaybe<UpdateOrderInput>;
};

export type MutationUpdateRoleArgs = {
  input?: InputMaybe<UpdateRoleInput>;
};

export type MutationUpdateUrlArgs = {
  input?: InputMaybe<UpdateUrlInput>;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['ID'];
  client: Maybe<UsersPermissionsUser>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  itemPrototype: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type OrderAggregator = {
  __typename?: 'OrderAggregator';
  count: Maybe<Scalars['Int']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  aggregate: Maybe<OrderAggregator>;
  groupBy: Maybe<OrderGroupBy>;
  values: Maybe<Array<Maybe<Order>>>;
};

export type OrderConnectionClient = {
  __typename?: 'OrderConnectionClient';
  connection: Maybe<OrderConnection>;
  key: Maybe<Scalars['ID']>;
};

export type OrderConnectionCreatedAt = {
  __typename?: 'OrderConnectionCreatedAt';
  connection: Maybe<OrderConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type OrderConnectionId = {
  __typename?: 'OrderConnectionId';
  connection: Maybe<OrderConnection>;
  key: Maybe<Scalars['ID']>;
};

export type OrderConnectionItemPrototype = {
  __typename?: 'OrderConnectionItemPrototype';
  connection: Maybe<OrderConnection>;
  key: Maybe<Scalars['JSON']>;
};

export type OrderConnectionUpdatedAt = {
  __typename?: 'OrderConnectionUpdatedAt';
  connection: Maybe<OrderConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type OrderConnectionUrl = {
  __typename?: 'OrderConnectionUrl';
  connection: Maybe<OrderConnection>;
  key: Maybe<Scalars['String']>;
};

export type OrderConnection_Id = {
  __typename?: 'OrderConnection_id';
  connection: Maybe<OrderConnection>;
  key: Maybe<Scalars['ID']>;
};

export type OrderGroupBy = {
  __typename?: 'OrderGroupBy';
  _id: Maybe<Array<Maybe<OrderConnection_Id>>>;
  client: Maybe<Array<Maybe<OrderConnectionClient>>>;
  createdAt: Maybe<Array<Maybe<OrderConnectionCreatedAt>>>;
  id: Maybe<Array<Maybe<OrderConnectionId>>>;
  itemPrototype: Maybe<Array<Maybe<OrderConnectionItemPrototype>>>;
  updatedAt: Maybe<Array<Maybe<OrderConnectionUpdatedAt>>>;
  url: Maybe<Array<Maybe<OrderConnectionUrl>>>;
};

export type OrderInput = {
  client?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  itemPrototype?: InputMaybe<Scalars['JSON']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  url: Scalars['String'];
};

export type PublicationState = 'LIVE' | 'PREVIEW';

export type Query = {
  __typename?: 'Query';
  ad: Maybe<Ad>;
  adUserData: Maybe<Array<Maybe<AdUserData>>>;
  adUserDataConnection: Maybe<AdUserDataConnection>;
  adUserDatum: Maybe<AdUserData>;
  ads: Maybe<Array<Maybe<Ad>>>;
  adsConnection: Maybe<AdConnection>;
  adsMine: Maybe<Array<Maybe<Ad>>>;
  areatag: Maybe<Areatag>;
  areatags: Maybe<Array<Maybe<Areatag>>>;
  areatagsConnection: Maybe<AreatagConnection>;
  files: Maybe<Array<Maybe<UploadFile>>>;
  filesConnection: Maybe<UploadFileConnection>;
  filter: Maybe<Filter>;
  filters: Maybe<Array<Maybe<Filter>>>;
  filtersConnection: Maybe<FilterConnection>;
  filtersMine: Maybe<Array<Maybe<Filter>>>;
  me: Maybe<UsersPermissionsMe>;
  myWatchlist: Maybe<Array<Maybe<Ad>>>;
  order: Maybe<Order>;
  orders: Maybe<Array<Maybe<Order>>>;
  ordersConnection: Maybe<OrderConnection>;
  role: Maybe<UsersPermissionsRole>;
  /** Retrieve all the existing roles. You can't apply filters on this query. */
  roles: Maybe<Array<Maybe<UsersPermissionsRole>>>;
  rolesConnection: Maybe<UsersPermissionsRoleConnection>;
  url: Maybe<Urls>;
  urls: Maybe<Array<Maybe<Urls>>>;
  urlsConnection: Maybe<UrlsConnection>;
  user: Maybe<UsersPermissionsUser>;
  users: Maybe<Array<Maybe<UsersPermissionsUser>>>;
  usersConnection: Maybe<UsersPermissionsUserConnection>;
};

export type QueryAdArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryAdUserDataArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryAdUserDataConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryAdUserDatumArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryAdsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryAdsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryAdsMineArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryAreatagArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryAreatagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryAreatagsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryFilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryFilesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryFilterArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryFiltersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryFiltersConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryFiltersMineArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryMyWatchlistArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryOrdersConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryRoleArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryRolesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryRolesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryUrlArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryUrlsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryUrlsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type QueryUsersConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type RoleInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  _id: Scalars['ID'];
  alternativeText: Maybe<Scalars['String']>;
  caption: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  ext: Maybe<Scalars['String']>;
  formats: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata: Maybe<Scalars['JSON']>;
  related: Maybe<Array<Maybe<Morph>>>;
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  width: Maybe<Scalars['Int']>;
};

export type UploadFileRelatedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type UploadFileAggregator = {
  __typename?: 'UploadFileAggregator';
  avg: Maybe<UploadFileAggregatorAvg>;
  count: Maybe<Scalars['Int']>;
  max: Maybe<UploadFileAggregatorMax>;
  min: Maybe<UploadFileAggregatorMin>;
  sum: Maybe<UploadFileAggregatorSum>;
  totalCount: Maybe<Scalars['Int']>;
};

export type UploadFileAggregatorAvg = {
  __typename?: 'UploadFileAggregatorAvg';
  height: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
  width: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorMax = {
  __typename?: 'UploadFileAggregatorMax';
  height: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
  width: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorMin = {
  __typename?: 'UploadFileAggregatorMin';
  height: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
  width: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorSum = {
  __typename?: 'UploadFileAggregatorSum';
  height: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
  width: Maybe<Scalars['Float']>;
};

export type UploadFileConnection = {
  __typename?: 'UploadFileConnection';
  aggregate: Maybe<UploadFileAggregator>;
  groupBy: Maybe<UploadFileGroupBy>;
  values: Maybe<Array<Maybe<UploadFile>>>;
};

export type UploadFileConnectionAlternativeText = {
  __typename?: 'UploadFileConnectionAlternativeText';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionCaption = {
  __typename?: 'UploadFileConnectionCaption';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionCreatedAt = {
  __typename?: 'UploadFileConnectionCreatedAt';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type UploadFileConnectionExt = {
  __typename?: 'UploadFileConnectionExt';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionFormats = {
  __typename?: 'UploadFileConnectionFormats';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['JSON']>;
};

export type UploadFileConnectionHash = {
  __typename?: 'UploadFileConnectionHash';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionHeight = {
  __typename?: 'UploadFileConnectionHeight';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['Int']>;
};

export type UploadFileConnectionId = {
  __typename?: 'UploadFileConnectionId';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UploadFileConnectionMime = {
  __typename?: 'UploadFileConnectionMime';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionName = {
  __typename?: 'UploadFileConnectionName';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionPreviewUrl = {
  __typename?: 'UploadFileConnectionPreviewUrl';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionProvider = {
  __typename?: 'UploadFileConnectionProvider';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionProvider_Metadata = {
  __typename?: 'UploadFileConnectionProvider_metadata';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['JSON']>;
};

export type UploadFileConnectionSize = {
  __typename?: 'UploadFileConnectionSize';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['Float']>;
};

export type UploadFileConnectionUpdatedAt = {
  __typename?: 'UploadFileConnectionUpdatedAt';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type UploadFileConnectionUrl = {
  __typename?: 'UploadFileConnectionUrl';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['String']>;
};

export type UploadFileConnectionWidth = {
  __typename?: 'UploadFileConnectionWidth';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['Int']>;
};

export type UploadFileConnection_Id = {
  __typename?: 'UploadFileConnection_id';
  connection: Maybe<UploadFileConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UploadFileGroupBy = {
  __typename?: 'UploadFileGroupBy';
  _id: Maybe<Array<Maybe<UploadFileConnection_Id>>>;
  alternativeText: Maybe<Array<Maybe<UploadFileConnectionAlternativeText>>>;
  caption: Maybe<Array<Maybe<UploadFileConnectionCaption>>>;
  createdAt: Maybe<Array<Maybe<UploadFileConnectionCreatedAt>>>;
  ext: Maybe<Array<Maybe<UploadFileConnectionExt>>>;
  formats: Maybe<Array<Maybe<UploadFileConnectionFormats>>>;
  hash: Maybe<Array<Maybe<UploadFileConnectionHash>>>;
  height: Maybe<Array<Maybe<UploadFileConnectionHeight>>>;
  id: Maybe<Array<Maybe<UploadFileConnectionId>>>;
  mime: Maybe<Array<Maybe<UploadFileConnectionMime>>>;
  name: Maybe<Array<Maybe<UploadFileConnectionName>>>;
  previewUrl: Maybe<Array<Maybe<UploadFileConnectionPreviewUrl>>>;
  provider: Maybe<Array<Maybe<UploadFileConnectionProvider>>>;
  provider_metadata: Maybe<Array<Maybe<UploadFileConnectionProvider_Metadata>>>;
  size: Maybe<Array<Maybe<UploadFileConnectionSize>>>;
  updatedAt: Maybe<Array<Maybe<UploadFileConnectionUpdatedAt>>>;
  url: Maybe<Array<Maybe<UploadFileConnectionUrl>>>;
  width: Maybe<Array<Maybe<UploadFileConnectionWidth>>>;
};

export type UrlInput = {
  ad?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  published_at?: InputMaybe<Scalars['DateTime']>;
  source?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Urls = {
  __typename?: 'Urls';
  _id: Scalars['ID'];
  ad: Maybe<Ad>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  published_at: Maybe<Scalars['DateTime']>;
  source: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url: Maybe<Scalars['String']>;
};

export type UrlsAggregator = {
  __typename?: 'UrlsAggregator';
  count: Maybe<Scalars['Int']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type UrlsConnection = {
  __typename?: 'UrlsConnection';
  aggregate: Maybe<UrlsAggregator>;
  groupBy: Maybe<UrlsGroupBy>;
  values: Maybe<Array<Maybe<Urls>>>;
};

export type UrlsConnectionAd = {
  __typename?: 'UrlsConnectionAd';
  connection: Maybe<UrlsConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UrlsConnectionCreatedAt = {
  __typename?: 'UrlsConnectionCreatedAt';
  connection: Maybe<UrlsConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type UrlsConnectionId = {
  __typename?: 'UrlsConnectionId';
  connection: Maybe<UrlsConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UrlsConnectionPublished_At = {
  __typename?: 'UrlsConnectionPublished_at';
  connection: Maybe<UrlsConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type UrlsConnectionSource = {
  __typename?: 'UrlsConnectionSource';
  connection: Maybe<UrlsConnection>;
  key: Maybe<Scalars['String']>;
};

export type UrlsConnectionUpdatedAt = {
  __typename?: 'UrlsConnectionUpdatedAt';
  connection: Maybe<UrlsConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type UrlsConnectionUrl = {
  __typename?: 'UrlsConnectionUrl';
  connection: Maybe<UrlsConnection>;
  key: Maybe<Scalars['String']>;
};

export type UrlsConnection_Id = {
  __typename?: 'UrlsConnection_id';
  connection: Maybe<UrlsConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UrlsGroupBy = {
  __typename?: 'UrlsGroupBy';
  _id: Maybe<Array<Maybe<UrlsConnection_Id>>>;
  ad: Maybe<Array<Maybe<UrlsConnectionAd>>>;
  createdAt: Maybe<Array<Maybe<UrlsConnectionCreatedAt>>>;
  id: Maybe<Array<Maybe<UrlsConnectionId>>>;
  published_at: Maybe<Array<Maybe<UrlsConnectionPublished_At>>>;
  source: Maybe<Array<Maybe<UrlsConnectionSource>>>;
  updatedAt: Maybe<Array<Maybe<UrlsConnectionUpdatedAt>>>;
  url: Maybe<Array<Maybe<UrlsConnectionUrl>>>;
};

export type UserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_by?: InputMaybe<Scalars['ID']>;
  currentFilter?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  username: Scalars['String'];
};

export type UserPermissionsPasswordPayload = {
  __typename?: 'UserPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked: Maybe<Scalars['Boolean']>;
  confirmed: Maybe<Scalars['Boolean']>;
  currentFilter: Maybe<Filter>;
  email: Scalars['String'];
  id: Scalars['ID'];
  role: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Maybe<Scalars['String']>;
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  _id: Scalars['ID'];
  action: Scalars['String'];
  controller: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  policy: Maybe<Scalars['String']>;
  role: Maybe<UsersPermissionsRole>;
  type: Scalars['String'];
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  _id: Scalars['ID'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Maybe<Array<Maybe<UsersPermissionsPermission>>>;
  type: Maybe<Scalars['String']>;
  users: Maybe<Array<Maybe<UsersPermissionsUser>>>;
};

export type UsersPermissionsRolePermissionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type UsersPermissionsRoleUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['JSON']>;
};

export type UsersPermissionsRoleAggregator = {
  __typename?: 'UsersPermissionsRoleAggregator';
  count: Maybe<Scalars['Int']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type UsersPermissionsRoleConnection = {
  __typename?: 'UsersPermissionsRoleConnection';
  aggregate: Maybe<UsersPermissionsRoleAggregator>;
  groupBy: Maybe<UsersPermissionsRoleGroupBy>;
  values: Maybe<Array<Maybe<UsersPermissionsRole>>>;
};

export type UsersPermissionsRoleConnectionDescription = {
  __typename?: 'UsersPermissionsRoleConnectionDescription';
  connection: Maybe<UsersPermissionsRoleConnection>;
  key: Maybe<Scalars['String']>;
};

export type UsersPermissionsRoleConnectionId = {
  __typename?: 'UsersPermissionsRoleConnectionId';
  connection: Maybe<UsersPermissionsRoleConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleConnectionName = {
  __typename?: 'UsersPermissionsRoleConnectionName';
  connection: Maybe<UsersPermissionsRoleConnection>;
  key: Maybe<Scalars['String']>;
};

export type UsersPermissionsRoleConnectionType = {
  __typename?: 'UsersPermissionsRoleConnectionType';
  connection: Maybe<UsersPermissionsRoleConnection>;
  key: Maybe<Scalars['String']>;
};

export type UsersPermissionsRoleConnection_Id = {
  __typename?: 'UsersPermissionsRoleConnection_id';
  connection: Maybe<UsersPermissionsRoleConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleGroupBy = {
  __typename?: 'UsersPermissionsRoleGroupBy';
  _id: Maybe<Array<Maybe<UsersPermissionsRoleConnection_Id>>>;
  description: Maybe<Array<Maybe<UsersPermissionsRoleConnectionDescription>>>;
  id: Maybe<Array<Maybe<UsersPermissionsRoleConnectionId>>>;
  name: Maybe<Array<Maybe<UsersPermissionsRoleConnectionName>>>;
  type: Maybe<Array<Maybe<UsersPermissionsRoleConnectionType>>>;
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  _id: Scalars['ID'];
  blocked: Maybe<Scalars['Boolean']>;
  confirmed: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  currentFilter: Maybe<Filter>;
  email: Scalars['String'];
  id: Scalars['ID'];
  provider: Maybe<Scalars['String']>;
  role: Maybe<UsersPermissionsRole>;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UsersPermissionsUserAggregator = {
  __typename?: 'UsersPermissionsUserAggregator';
  count: Maybe<Scalars['Int']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type UsersPermissionsUserConnection = {
  __typename?: 'UsersPermissionsUserConnection';
  aggregate: Maybe<UsersPermissionsUserAggregator>;
  groupBy: Maybe<UsersPermissionsUserGroupBy>;
  values: Maybe<Array<Maybe<UsersPermissionsUser>>>;
};

export type UsersPermissionsUserConnectionBlocked = {
  __typename?: 'UsersPermissionsUserConnectionBlocked';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['Boolean']>;
};

export type UsersPermissionsUserConnectionConfirmed = {
  __typename?: 'UsersPermissionsUserConnectionConfirmed';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['Boolean']>;
};

export type UsersPermissionsUserConnectionCreatedAt = {
  __typename?: 'UsersPermissionsUserConnectionCreatedAt';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsUserConnectionCurrentFilter = {
  __typename?: 'UsersPermissionsUserConnectionCurrentFilter';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserConnectionEmail = {
  __typename?: 'UsersPermissionsUserConnectionEmail';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['String']>;
};

export type UsersPermissionsUserConnectionId = {
  __typename?: 'UsersPermissionsUserConnectionId';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserConnectionProvider = {
  __typename?: 'UsersPermissionsUserConnectionProvider';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['String']>;
};

export type UsersPermissionsUserConnectionRole = {
  __typename?: 'UsersPermissionsUserConnectionRole';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserConnectionUpdatedAt = {
  __typename?: 'UsersPermissionsUserConnectionUpdatedAt';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsUserConnectionUsername = {
  __typename?: 'UsersPermissionsUserConnectionUsername';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['String']>;
};

export type UsersPermissionsUserConnection_Id = {
  __typename?: 'UsersPermissionsUserConnection_id';
  connection: Maybe<UsersPermissionsUserConnection>;
  key: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserGroupBy = {
  __typename?: 'UsersPermissionsUserGroupBy';
  _id: Maybe<Array<Maybe<UsersPermissionsUserConnection_Id>>>;
  blocked: Maybe<Array<Maybe<UsersPermissionsUserConnectionBlocked>>>;
  confirmed: Maybe<Array<Maybe<UsersPermissionsUserConnectionConfirmed>>>;
  createdAt: Maybe<Array<Maybe<UsersPermissionsUserConnectionCreatedAt>>>;
  currentFilter: Maybe<
    Array<Maybe<UsersPermissionsUserConnectionCurrentFilter>>
  >;
  email: Maybe<Array<Maybe<UsersPermissionsUserConnectionEmail>>>;
  id: Maybe<Array<Maybe<UsersPermissionsUserConnectionId>>>;
  provider: Maybe<Array<Maybe<UsersPermissionsUserConnectionProvider>>>;
  role: Maybe<Array<Maybe<UsersPermissionsUserConnectionRole>>>;
  updatedAt: Maybe<Array<Maybe<UsersPermissionsUserConnectionUpdatedAt>>>;
  username: Maybe<Array<Maybe<UsersPermissionsUserConnectionUsername>>>;
};

export type CreateAdInput = {
  data?: InputMaybe<AdInput>;
};

export type CreateAdPayload = {
  __typename?: 'createAdPayload';
  ad: Maybe<Ad>;
};

export type CreateAdUserDatumInput = {
  data?: InputMaybe<AdUserDatumInput>;
};

export type CreateAdUserDatumPayload = {
  __typename?: 'createAdUserDatumPayload';
  adUserDatum: Maybe<AdUserData>;
};

export type CreateAreatagInput = {
  data?: InputMaybe<AreatagInput>;
};

export type CreateAreatagPayload = {
  __typename?: 'createAreatagPayload';
  areatag: Maybe<Areatag>;
};

export type CreateFilterInput = {
  data?: InputMaybe<FilterInput>;
};

export type CreateFilterPayload = {
  __typename?: 'createFilterPayload';
  filter: Maybe<Filter>;
};

export type CreateOrderInput = {
  data?: InputMaybe<OrderInput>;
};

export type CreateOrderPayload = {
  __typename?: 'createOrderPayload';
  order: Maybe<Order>;
};

export type CreateRoleInput = {
  data?: InputMaybe<RoleInput>;
};

export type CreateRolePayload = {
  __typename?: 'createRolePayload';
  role: Maybe<UsersPermissionsRole>;
};

export type CreateUrlInput = {
  data?: InputMaybe<UrlInput>;
};

export type CreateUrlPayload = {
  __typename?: 'createUrlPayload';
  url: Maybe<Urls>;
};

export type CreateUserInput = {
  data?: InputMaybe<UserInput>;
};

export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  user: Maybe<UsersPermissionsUser>;
};

export type DeleteAdInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteAdPayload = {
  __typename?: 'deleteAdPayload';
  ad: Maybe<Ad>;
};

export type DeleteAdUserDatumInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteAdUserDatumPayload = {
  __typename?: 'deleteAdUserDatumPayload';
  adUserDatum: Maybe<AdUserData>;
};

export type DeleteAreatagInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteAreatagPayload = {
  __typename?: 'deleteAreatagPayload';
  areatag: Maybe<Areatag>;
};

export type DeleteFileInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteFilePayload = {
  __typename?: 'deleteFilePayload';
  file: Maybe<UploadFile>;
};

export type DeleteFilterInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteFilterPayload = {
  __typename?: 'deleteFilterPayload';
  filter: Maybe<Filter>;
};

export type DeleteOrderInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteOrderPayload = {
  __typename?: 'deleteOrderPayload';
  order: Maybe<Order>;
};

export type DeleteRoleInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteRolePayload = {
  __typename?: 'deleteRolePayload';
  role: Maybe<UsersPermissionsRole>;
};

export type DeleteUrlInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteUrlPayload = {
  __typename?: 'deleteUrlPayload';
  url: Maybe<Urls>;
};

export type DeleteUserInput = {
  where?: InputMaybe<InputId>;
};

export type DeleteUserPayload = {
  __typename?: 'deleteUserPayload';
  user: Maybe<UsersPermissionsUser>;
};

export type EditAdInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  adType?: InputMaybe<Enum_Ad_Adtype>;
  addedAt?: InputMaybe<Scalars['DateTime']>;
  area?: InputMaybe<Scalars['String']>;
  areatags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  city?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  incomplete?: InputMaybe<Scalars['Boolean']>;
  isWatched?: InputMaybe<Scalars['Boolean']>;
  maintenanceMonthlyCost?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  offerDigest?: InputMaybe<Scalars['String']>;
  offerObject?: InputMaybe<Enum_Ad_Offerobject>;
  price?: InputMaybe<Scalars['Int']>;
  pricePerSquareMeter?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  rooms?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Enum_Ad_State>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_Ad_Type>;
  updated_by?: InputMaybe<Scalars['ID']>;
  urls?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type EditAdUserDatumInput = {
  ad?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  isWatched?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Enum_Aduserdata_State>;
  updated_by?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type EditAreatagInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditComponentGeneralRangeInput = {
  id?: InputMaybe<Scalars['ID']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type EditFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  ext?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  related?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  size?: InputMaybe<Scalars['Float']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type EditFilterInput = {
  area?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<EditComponentGeneralRangeInput>;
  pricePerSquareMeter?: InputMaybe<EditComponentGeneralRangeInput>;
  published_at?: InputMaybe<Scalars['DateTime']>;
  size?: InputMaybe<EditComponentGeneralRangeInput>;
  updated_by?: InputMaybe<Scalars['ID']>;
};

export type EditOrderInput = {
  client?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  itemPrototype?: InputMaybe<Scalars['JSON']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
};

export type EditRoleInput = {
  created_by?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type EditUrlInput = {
  ad?: InputMaybe<Scalars['ID']>;
  created_by?: InputMaybe<Scalars['ID']>;
  published_at?: InputMaybe<Scalars['DateTime']>;
  source?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
};

export type EditUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_by?: InputMaybe<Scalars['ID']>;
  currentFilter?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  updated_by?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateAdInput = {
  data?: InputMaybe<EditAdInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateAdPayload = {
  __typename?: 'updateAdPayload';
  ad: Maybe<Ad>;
};

export type UpdateAdUserDatumInput = {
  data?: InputMaybe<EditAdUserDatumInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateAdUserDatumPayload = {
  __typename?: 'updateAdUserDatumPayload';
  adUserDatum: Maybe<AdUserData>;
};

export type UpdateAreatagInput = {
  data?: InputMaybe<EditAreatagInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateAreatagPayload = {
  __typename?: 'updateAreatagPayload';
  areatag: Maybe<Areatag>;
};

export type UpdateFilterInput = {
  data?: InputMaybe<EditFilterInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateFilterPayload = {
  __typename?: 'updateFilterPayload';
  filter: Maybe<Filter>;
};

export type UpdateOrderInput = {
  data?: InputMaybe<EditOrderInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateOrderPayload = {
  __typename?: 'updateOrderPayload';
  order: Maybe<Order>;
};

export type UpdateRoleInput = {
  data?: InputMaybe<EditRoleInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateRolePayload = {
  __typename?: 'updateRolePayload';
  role: Maybe<UsersPermissionsRole>;
};

export type UpdateUrlInput = {
  data?: InputMaybe<EditUrlInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateUrlPayload = {
  __typename?: 'updateUrlPayload';
  url: Maybe<Urls>;
};

export type UpdateUserInput = {
  data?: InputMaybe<EditUserInput>;
  where?: InputMaybe<InputId>;
};

export type UpdateUserPayload = {
  __typename?: 'updateUserPayload';
  user: Maybe<UsersPermissionsUser>;
};

export type UpdateAdMutationVariables = Exact<{
  id: Scalars['ID'];
  data?: InputMaybe<EditAdInput>;
}>;

export type UpdateAdMutation = {
  __typename?: 'Mutation';
  updateAd: {
    __typename?: 'updateAdPayload';
    ad: {
      __typename?: 'Ad';
      id: string;
      title: string | null;
      description: string | null;
      price: number | null;
      maintenanceMonthlyCost: number | null;
      created_at: DateTime;
      pricePerSquareMeter: number | null;
      sourceUrl: string | null;
      state: Enum_Ad_State | null;
      adType: Enum_Ad_Adtype | null;
      isWatched: boolean | null;
      note: string | null;
    } | null;
  } | null;
};

export type AddToWatchlistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AddToWatchlistMutation = {
  __typename?: 'Mutation';
  addToWatchlist: {
    __typename?: 'Ad';
    id: string;
    title: string | null;
    description: string | null;
    price: number | null;
    maintenanceMonthlyCost: number | null;
    created_at: DateTime;
    pricePerSquareMeter: number | null;
    sourceUrl: string | null;
    state: Enum_Ad_State | null;
    adType: Enum_Ad_Adtype | null;
    isWatched: boolean | null;
    note: string | null;
  };
};

export type RemoveFromWatchlistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveFromWatchlistMutation = {
  __typename?: 'Mutation';
  removeFromWatchlist: {
    __typename?: 'Ad';
    id: string;
    title: string | null;
    description: string | null;
    price: number | null;
    maintenanceMonthlyCost: number | null;
    created_at: DateTime;
    pricePerSquareMeter: number | null;
    sourceUrl: string | null;
    state: Enum_Ad_State | null;
    adType: Enum_Ad_Adtype | null;
    isWatched: boolean | null;
    note: string | null;
  };
};

export type AdsQueryVariables = Exact<{
  limit: Scalars['Int'];
  start: Scalars['Int'];
  area: Scalars['String'];
  maxPricePerSquareMeter?: InputMaybe<Scalars['Int']>;
  minPricePerSquareMeter?: InputMaybe<Scalars['Int']>;
  maxSize?: InputMaybe<Scalars['Int']>;
  minSize?: InputMaybe<Scalars['Int']>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
}>;

export type AdsQuery = {
  __typename?: 'Query';
  ads: Array<{
    __typename?: 'Ad';
    id: string;
    title: string | null;
    description: string | null;
    price: number | null;
    maintenanceMonthlyCost: number | null;
    created_at: DateTime;
    pricePerSquareMeter: number | null;
    sourceUrl: string | null;
    state: Enum_Ad_State | null;
    adType: Enum_Ad_Adtype | null;
    isWatched: boolean | null;
    note: string | null;
  } | null> | null;
};

export type WatchlistQueryVariables = Exact<{
  limit: Scalars['Int'];
  start: Scalars['Int'];
}>;

export type WatchlistQuery = {
  __typename?: 'Query';
  myWatchlist: Array<{
    __typename?: 'Ad';
    id: string;
    title: string | null;
    description: string | null;
    price: number | null;
    maintenanceMonthlyCost: number | null;
    created_at: DateTime;
    pricePerSquareMeter: number | null;
    sourceUrl: string | null;
    state: Enum_Ad_State | null;
    adType: Enum_Ad_Adtype | null;
    isWatched: boolean | null;
    note: string | null;
  } | null> | null;
};

export type MyFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type MyFiltersQuery = {
  __typename?: 'Query';
  filtersMine: Array<{
    __typename?: 'Filter';
    id: string;
    area: string;
    name: string;
    pricePerSquareMeter: {
      __typename?: 'ComponentGeneralRange';
      min: number | null;
      max: number | null;
    } | null;
    size: {
      __typename?: 'ComponentGeneralRange';
      min: number | null;
      max: number | null;
    } | null;
    price: {
      __typename?: 'ComponentGeneralRange';
      min: number | null;
      max: number | null;
    } | null;
  } | null> | null;
};

export type SetFilterMutationVariables = Exact<{
  filterId: Scalars['ID'];
}>;

export type SetFilterMutation = {
  __typename?: 'Mutation';
  setFilter: {
    __typename?: 'UsersPermissionsMe';
    id: string;
    username: string;
    email: string;
    confirmed: boolean | null;
    blocked: boolean | null;
    role: { __typename?: 'UsersPermissionsMeRole'; name: string } | null;
    currentFilter: {
      __typename?: 'Filter';
      id: string;
      area: string;
      name: string;
      pricePerSquareMeter: {
        __typename?: 'ComponentGeneralRange';
        min: number | null;
        max: number | null;
      } | null;
      size: {
        __typename?: 'ComponentGeneralRange';
        min: number | null;
        max: number | null;
      } | null;
      price: {
        __typename?: 'ComponentGeneralRange';
        min: number | null;
        max: number | null;
      } | null;
    } | null;
  } | null;
};

export type AdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AdQuery = {
  __typename?: 'Query';
  ad: {
    __typename?: 'Ad';
    id: string;
    title: string | null;
    description: string | null;
    price: number | null;
    maintenanceMonthlyCost: number | null;
    created_at: DateTime;
    pricePerSquareMeter: number | null;
    sourceUrl: string | null;
    state: Enum_Ad_State | null;
    adType: Enum_Ad_Adtype | null;
    isWatched: boolean | null;
    note: string | null;
  } | null;
};

export type AdFragmentFragment = {
  __typename?: 'Ad';
  id: string;
  title: string | null;
  description: string | null;
  price: number | null;
  maintenanceMonthlyCost: number | null;
  created_at: DateTime;
  pricePerSquareMeter: number | null;
  sourceUrl: string | null;
  state: Enum_Ad_State | null;
  adType: Enum_Ad_Adtype | null;
  isWatched: boolean | null;
  note: string | null;
};

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'UsersPermissionsLoginPayload';
    jwt: string | null;
    user: { __typename?: 'UsersPermissionsMe'; email: string };
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UsersPermissionsMe';
    id: string;
    username: string;
    email: string;
    confirmed: boolean | null;
    blocked: boolean | null;
    role: { __typename?: 'UsersPermissionsMeRole'; name: string } | null;
    currentFilter: {
      __typename?: 'Filter';
      id: string;
      area: string;
      name: string;
      pricePerSquareMeter: {
        __typename?: 'ComponentGeneralRange';
        min: number | null;
        max: number | null;
      } | null;
      size: {
        __typename?: 'ComponentGeneralRange';
        min: number | null;
        max: number | null;
      } | null;
      price: {
        __typename?: 'ComponentGeneralRange';
        min: number | null;
        max: number | null;
      } | null;
    } | null;
  } | null;
};

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  password1: Scalars['String'];
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register: {
    __typename?: 'UsersPermissionsLoginPayload';
    jwt: string | null;
    user: { __typename?: 'UsersPermissionsMe'; username: string };
  };
};

export const AdFragmentFragmentDoc = gql`
  fragment AdFragment on Ad {
    id
    title
    description
    price
    maintenanceMonthlyCost
    created_at
    pricePerSquareMeter
    sourceUrl
    state
    adType
    isWatched
    note
  }
`;
export const UpdateAdDocument = gql`
  mutation UpdateAd($id: ID!, $data: editAdInput) {
    updateAd(input: { where: { id: $id }, data: $data }) {
      ad {
        ...AdFragment
      }
    }
  }
  ${AdFragmentFragmentDoc}
`;
export type UpdateAdMutationFn = Apollo.MutationFunction<
  UpdateAdMutation,
  UpdateAdMutationVariables
>;

/**
 * __useUpdateAdMutation__
 *
 * To run a mutation, you first call `useUpdateAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdMutation, { data, loading, error }] = useUpdateAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdMutation,
    UpdateAdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdMutation, UpdateAdMutationVariables>(
    UpdateAdDocument,
    options
  );
}
export type UpdateAdMutationHookResult = ReturnType<typeof useUpdateAdMutation>;
export type UpdateAdMutationResult = Apollo.MutationResult<UpdateAdMutation>;
export type UpdateAdMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdMutation,
  UpdateAdMutationVariables
>;
export const AddToWatchlistDocument = gql`
  mutation AddToWatchlist($id: ID!) {
    addToWatchlist(id: $id) {
      ...AdFragment
    }
  }
  ${AdFragmentFragmentDoc}
`;
export type AddToWatchlistMutationFn = Apollo.MutationFunction<
  AddToWatchlistMutation,
  AddToWatchlistMutationVariables
>;

/**
 * __useAddToWatchlistMutation__
 *
 * To run a mutation, you first call `useAddToWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWatchlistMutation, { data, loading, error }] = useAddToWatchlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddToWatchlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddToWatchlistMutation,
    AddToWatchlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddToWatchlistMutation,
    AddToWatchlistMutationVariables
  >(AddToWatchlistDocument, options);
}
export type AddToWatchlistMutationHookResult = ReturnType<
  typeof useAddToWatchlistMutation
>;
export type AddToWatchlistMutationResult =
  Apollo.MutationResult<AddToWatchlistMutation>;
export type AddToWatchlistMutationOptions = Apollo.BaseMutationOptions<
  AddToWatchlistMutation,
  AddToWatchlistMutationVariables
>;
export const RemoveFromWatchlistDocument = gql`
  mutation RemoveFromWatchlist($id: ID!) {
    removeFromWatchlist(id: $id) {
      ...AdFragment
    }
  }
  ${AdFragmentFragmentDoc}
`;
export type RemoveFromWatchlistMutationFn = Apollo.MutationFunction<
  RemoveFromWatchlistMutation,
  RemoveFromWatchlistMutationVariables
>;

/**
 * __useRemoveFromWatchlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromWatchlistMutation, { data, loading, error }] = useRemoveFromWatchlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFromWatchlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromWatchlistMutation,
    RemoveFromWatchlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFromWatchlistMutation,
    RemoveFromWatchlistMutationVariables
  >(RemoveFromWatchlistDocument, options);
}
export type RemoveFromWatchlistMutationHookResult = ReturnType<
  typeof useRemoveFromWatchlistMutation
>;
export type RemoveFromWatchlistMutationResult =
  Apollo.MutationResult<RemoveFromWatchlistMutation>;
export type RemoveFromWatchlistMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromWatchlistMutation,
  RemoveFromWatchlistMutationVariables
>;
export const AdsDocument = gql`
  query Ads(
    $limit: Int!
    $start: Int!
    $area: String!
    $maxPricePerSquareMeter: Int
    $minPricePerSquareMeter: Int
    $maxSize: Int
    $minSize: Int
    $maxPrice: Int
    $minPrice: Int
  ) {
    ads(
      limit: $limit
      start: $start
      sort: "created_at:desc"
      where: {
        pricePerSquareMeter_gte: $minPricePerSquareMeter
        pricePerSquareMeter_lte: $maxPricePerSquareMeter
        city: $area
        size_gte: $minSize
        size_lte: $maxSize
        price_lte: $maxPrice
        price_gte: $minPrice
      }
    ) {
      ...AdFragment
    }
  }
  ${AdFragmentFragmentDoc}
`;

/**
 * __useAdsQuery__
 *
 * To run a query within a React component, call `useAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *      area: // value for 'area'
 *      maxPricePerSquareMeter: // value for 'maxPricePerSquareMeter'
 *      minPricePerSquareMeter: // value for 'minPricePerSquareMeter'
 *      maxSize: // value for 'maxSize'
 *      minSize: // value for 'minSize'
 *      maxPrice: // value for 'maxPrice'
 *      minPrice: // value for 'minPrice'
 *   },
 * });
 */
export function useAdsQuery(
  baseOptions: Apollo.QueryHookOptions<AdsQuery, AdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdsQuery, AdsQueryVariables>(AdsDocument, options);
}
export function useAdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdsQuery, AdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdsQuery, AdsQueryVariables>(AdsDocument, options);
}
export type AdsQueryHookResult = ReturnType<typeof useAdsQuery>;
export type AdsLazyQueryHookResult = ReturnType<typeof useAdsLazyQuery>;
export type AdsQueryResult = Apollo.QueryResult<AdsQuery, AdsQueryVariables>;
export const WatchlistDocument = gql`
  query Watchlist($limit: Int!, $start: Int!) {
    myWatchlist(limit: $limit, start: $start, sort: "created_at:desc") {
      ...AdFragment
    }
  }
  ${AdFragmentFragmentDoc}
`;

/**
 * __useWatchlistQuery__
 *
 * To run a query within a React component, call `useWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchlistQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useWatchlistQuery(
  baseOptions: Apollo.QueryHookOptions<WatchlistQuery, WatchlistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WatchlistQuery, WatchlistQueryVariables>(
    WatchlistDocument,
    options
  );
}
export function useWatchlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WatchlistQuery,
    WatchlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WatchlistQuery, WatchlistQueryVariables>(
    WatchlistDocument,
    options
  );
}
export type WatchlistQueryHookResult = ReturnType<typeof useWatchlistQuery>;
export type WatchlistLazyQueryHookResult = ReturnType<
  typeof useWatchlistLazyQuery
>;
export type WatchlistQueryResult = Apollo.QueryResult<
  WatchlistQuery,
  WatchlistQueryVariables
>;
export const MyFiltersDocument = gql`
  query MyFilters {
    filtersMine {
      id
      area
      pricePerSquareMeter {
        min
        max
      }
      size {
        min
        max
      }
      name
      price {
        min
        max
      }
    }
  }
`;

/**
 * __useMyFiltersQuery__
 *
 * To run a query within a React component, call `useMyFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<MyFiltersQuery, MyFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyFiltersQuery, MyFiltersQueryVariables>(
    MyFiltersDocument,
    options
  );
}
export function useMyFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyFiltersQuery,
    MyFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyFiltersQuery, MyFiltersQueryVariables>(
    MyFiltersDocument,
    options
  );
}
export type MyFiltersQueryHookResult = ReturnType<typeof useMyFiltersQuery>;
export type MyFiltersLazyQueryHookResult = ReturnType<
  typeof useMyFiltersLazyQuery
>;
export type MyFiltersQueryResult = Apollo.QueryResult<
  MyFiltersQuery,
  MyFiltersQueryVariables
>;
export const SetFilterDocument = gql`
  mutation SetFilter($filterId: ID!) {
    setFilter(filterId: $filterId) {
      id
      username
      email
      confirmed
      blocked
      role {
        name
      }
      currentFilter {
        id
        area
        pricePerSquareMeter {
          min
          max
        }
        size {
          min
          max
        }
        price {
          min
          max
        }
        name
      }
    }
  }
`;
export type SetFilterMutationFn = Apollo.MutationFunction<
  SetFilterMutation,
  SetFilterMutationVariables
>;

/**
 * __useSetFilterMutation__
 *
 * To run a mutation, you first call `useSetFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFilterMutation, { data, loading, error }] = useSetFilterMutation({
 *   variables: {
 *      filterId: // value for 'filterId'
 *   },
 * });
 */
export function useSetFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetFilterMutation,
    SetFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetFilterMutation, SetFilterMutationVariables>(
    SetFilterDocument,
    options
  );
}
export type SetFilterMutationHookResult = ReturnType<
  typeof useSetFilterMutation
>;
export type SetFilterMutationResult = Apollo.MutationResult<SetFilterMutation>;
export type SetFilterMutationOptions = Apollo.BaseMutationOptions<
  SetFilterMutation,
  SetFilterMutationVariables
>;
export const AdDocument = gql`
  query Ad($id: ID!) {
    ad(id: $id, publicationState: LIVE) {
      ...AdFragment
    }
  }
  ${AdFragmentFragmentDoc}
`;

/**
 * __useAdQuery__
 *
 * To run a query within a React component, call `useAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdQuery(
  baseOptions: Apollo.QueryHookOptions<AdQuery, AdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdQuery, AdQueryVariables>(AdDocument, options);
}
export function useAdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdQuery, AdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdQuery, AdQueryVariables>(AdDocument, options);
}
export type AdQueryHookResult = ReturnType<typeof useAdQuery>;
export type AdLazyQueryHookResult = ReturnType<typeof useAdLazyQuery>;
export type AdQueryResult = Apollo.QueryResult<AdQuery, AdQueryVariables>;
export const LoginDocument = gql`
  mutation Login($username: String!, $password: String!) {
    login(input: { identifier: $username, password: $password }) {
      user {
        email
      }
      jwt
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      id
      username
      email
      confirmed
      blocked
      role {
        name
      }
      currentFilter {
        id
        area
        pricePerSquareMeter {
          min
          max
        }
        size {
          min
          max
        }
        price {
          min
          max
        }
        name
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RegisterDocument = gql`
  mutation Register($email: String!, $password1: String!) {
    register(input: { email: $email, username: $email, password: $password1 }) {
      user {
        username
      }
      jwt
    }
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password1: // value for 'password1'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Morph: [
      'Ad',
      'AdAggregator',
      'AdAggregatorAvg',
      'AdAggregatorMax',
      'AdAggregatorMin',
      'AdAggregatorSum',
      'AdConnection',
      'AdConnectionAdType',
      'AdConnectionAddedAt',
      'AdConnectionArea',
      'AdConnectionCity',
      'AdConnectionCreated_at',
      'AdConnectionDescription',
      'AdConnectionId',
      'AdConnectionIncomplete',
      'AdConnectionIsWatched',
      'AdConnectionMaintenanceMonthlyCost',
      'AdConnectionNote',
      'AdConnectionOfferDigest',
      'AdConnectionOfferObject',
      'AdConnectionPrice',
      'AdConnectionPricePerSquareMeter',
      'AdConnectionRooms',
      'AdConnectionSize',
      'AdConnectionSourceUrl',
      'AdConnectionState',
      'AdConnectionTitle',
      'AdConnectionType',
      'AdConnectionUpdated_at',
      'AdConnection_id',
      'AdGroupBy',
      'AdUserData',
      'AdUserDataAggregator',
      'AdUserDataConnection',
      'AdUserDataConnectionAd',
      'AdUserDataConnectionCreatedAt',
      'AdUserDataConnectionId',
      'AdUserDataConnectionIsWatched',
      'AdUserDataConnectionNote',
      'AdUserDataConnectionState',
      'AdUserDataConnectionUpdatedAt',
      'AdUserDataConnectionUser',
      'AdUserDataConnection_id',
      'AdUserDataGroupBy',
      'Areatag',
      'AreatagAggregator',
      'AreatagConnection',
      'AreatagConnectionCreatedAt',
      'AreatagConnectionId',
      'AreatagConnectionName',
      'AreatagConnectionUpdatedAt',
      'AreatagConnection_id',
      'AreatagGroupBy',
      'ComponentGeneralRange',
      'Filter',
      'FilterAggregator',
      'FilterConnection',
      'FilterConnectionArea',
      'FilterConnectionCreatedAt',
      'FilterConnectionId',
      'FilterConnectionName',
      'FilterConnectionOwner',
      'FilterConnectionPrice',
      'FilterConnectionPricePerSquareMeter',
      'FilterConnectionPublished_at',
      'FilterConnectionSize',
      'FilterConnectionUpdatedAt',
      'FilterConnection_id',
      'FilterGroupBy',
      'Order',
      'OrderAggregator',
      'OrderConnection',
      'OrderConnectionClient',
      'OrderConnectionCreatedAt',
      'OrderConnectionId',
      'OrderConnectionItemPrototype',
      'OrderConnectionUpdatedAt',
      'OrderConnectionUrl',
      'OrderConnection_id',
      'OrderGroupBy',
      'UploadFile',
      'UploadFileAggregator',
      'UploadFileAggregatorAvg',
      'UploadFileAggregatorMax',
      'UploadFileAggregatorMin',
      'UploadFileAggregatorSum',
      'UploadFileConnection',
      'UploadFileConnectionAlternativeText',
      'UploadFileConnectionCaption',
      'UploadFileConnectionCreatedAt',
      'UploadFileConnectionExt',
      'UploadFileConnectionFormats',
      'UploadFileConnectionHash',
      'UploadFileConnectionHeight',
      'UploadFileConnectionId',
      'UploadFileConnectionMime',
      'UploadFileConnectionName',
      'UploadFileConnectionPreviewUrl',
      'UploadFileConnectionProvider',
      'UploadFileConnectionProvider_metadata',
      'UploadFileConnectionSize',
      'UploadFileConnectionUpdatedAt',
      'UploadFileConnectionUrl',
      'UploadFileConnectionWidth',
      'UploadFileConnection_id',
      'UploadFileGroupBy',
      'Urls',
      'UrlsAggregator',
      'UrlsConnection',
      'UrlsConnectionAd',
      'UrlsConnectionCreatedAt',
      'UrlsConnectionId',
      'UrlsConnectionPublished_at',
      'UrlsConnectionSource',
      'UrlsConnectionUpdatedAt',
      'UrlsConnectionUrl',
      'UrlsConnection_id',
      'UrlsGroupBy',
      'UserPermissionsPasswordPayload',
      'UsersPermissionsLoginPayload',
      'UsersPermissionsMe',
      'UsersPermissionsMeRole',
      'UsersPermissionsPermission',
      'UsersPermissionsRole',
      'UsersPermissionsRoleAggregator',
      'UsersPermissionsRoleConnection',
      'UsersPermissionsRoleConnectionDescription',
      'UsersPermissionsRoleConnectionId',
      'UsersPermissionsRoleConnectionName',
      'UsersPermissionsRoleConnectionType',
      'UsersPermissionsRoleConnection_id',
      'UsersPermissionsRoleGroupBy',
      'UsersPermissionsUser',
      'UsersPermissionsUserAggregator',
      'UsersPermissionsUserConnection',
      'UsersPermissionsUserConnectionBlocked',
      'UsersPermissionsUserConnectionConfirmed',
      'UsersPermissionsUserConnectionCreatedAt',
      'UsersPermissionsUserConnectionCurrentFilter',
      'UsersPermissionsUserConnectionEmail',
      'UsersPermissionsUserConnectionId',
      'UsersPermissionsUserConnectionProvider',
      'UsersPermissionsUserConnectionRole',
      'UsersPermissionsUserConnectionUpdatedAt',
      'UsersPermissionsUserConnectionUsername',
      'UsersPermissionsUserConnection_id',
      'UsersPermissionsUserGroupBy',
      'createAdPayload',
      'createAdUserDatumPayload',
      'createAreatagPayload',
      'createFilterPayload',
      'createOrderPayload',
      'createRolePayload',
      'createUrlPayload',
      'createUserPayload',
      'deleteAdPayload',
      'deleteAdUserDatumPayload',
      'deleteAreatagPayload',
      'deleteFilePayload',
      'deleteFilterPayload',
      'deleteOrderPayload',
      'deleteRolePayload',
      'deleteUrlPayload',
      'deleteUserPayload',
      'updateAdPayload',
      'updateAdUserDatumPayload',
      'updateAreatagPayload',
      'updateFilterPayload',
      'updateOrderPayload',
      'updateRolePayload',
      'updateUrlPayload',
      'updateUserPayload'
    ]
  }
};
export default result;
