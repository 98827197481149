import React from 'react';
import './offer.sass';
import { Offer as OfferModel } from '../models/offer';
import { useCT, useT } from 'public/hooks/translation';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import { AdMutationsWrapper, AdContext } from 'app/ads-browser/api/ad';
import { Enum_Ad_State } from 'generated/graphql';
import { Select } from 'public/forms/components/select';
import { capitalizeFirstLetter } from 'public/util';
import { useAddNoteDialog } from 'app/ads-browser/components/add-note-dialog';
import { merge } from 'public/css';

const useStyles = makeStyles<Theme>(theme => ({
  offerRoot: {
    maxHeight: '360px',
    [theme.breakpoints.down('md')]: {
      padding: '0 0 20px 0'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 7px 20px 7px'
    },
    flex: 1
  },
  offerLink: {
    textDecoration: 'none',
    color: 'unset',
    '&:hover, :active, :focus': {
      textDecoration: 'none',
      color: 'unset'
    }
  },
  offerTitle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'calc(100% - 15px)',
    fontWeight: 'bold',
    marginBottom: '8px'
  },
  offerPriceHeader: {
    fontSize: '0.8rem',
    marginBottom: '0px'
  },
  offerPrice: {
    fontSize: '0.8rem',
    fontWeight: 'bold'
  }
}));

export interface Props {
  data: OfferModel;
  className?: string;
}

export function Offer({ data, className }: Props): JSX.Element {
  const t = useT();
  const ct = useCT();
  const classes = useStyles();
  const { open: openAddNote } = useAddNoteDialog(data);
  return (
    <AdContext.Provider value={data}>
      <AdMutationsWrapper className={className}>
        {({ update, setVisited, addToWatchlist, removeFromWatchlist }) => (
          <div
            className={merge(
              `${classes.offerRoot} offer-root`,
              data.state === 'visited' && 'visited',
              data.state === 'uninteresting' && 'uninteresting',
              data.state === 'expired_url' && 'uninteresting',
              data.state === 'sold' && 'uninteresting',
              data.state === 'presold' && 'uninteresting',
              !!data.isWatched && 'watched'
            )}
          >
            <a
              href={data.sourceUrl as string}
              className={classes.offerLink}
              referrerPolicy={'no-referrer'}
              rel={'noopener noreferrer nofollow'}
              target={'_blank'}
              onMouseDown={() => setVisited()}
            >
              <div>
                <Typography
                  variant='h3'
                  component='h3'
                  className={classes.offerTitle}
                >
                  {data.title}
                </Typography>
                <p className={classes.offerPriceHeader}>
                  {t('price').toUpperCase()}
                </p>
                <p className={classes.offerPrice}>
                  {data.pricePerSquareMeter && (
                    <span>
                      {data.pricePerSquareMeter} {t('pln')}/m2
                    </span>
                  )}
                  {data.pricePerSquareMeter && data.price && ' - '}
                  {data.price && (
                    <span>
                      {data.price} {t('pln')}
                    </span>
                  )}
                </p>
                <p className={classes.offerPrice}>
                  {data.created_at.toLocaleString(
                    DateTime.DATETIME_FULL as LocaleOptions &
                      DateTimeFormatOptions
                  )}
                </p>
                {data.adType === 'private' && (
                  <p style={{ fontWeight: 700, color: 'red' }}>Prywatne</p>
                )}
              </div>
            </a>
            <div
              onClick={event => event.stopPropagation()}
              onMouseDown={event => event.stopPropagation()}
            >
              <Select<Enum_Ad_State>
                placeholder={'Ad status'}
                options={[
                  'new',
                  'expired_url',
                  'sold',
                  'presold',
                  'uninteresting',
                  'visited'
                ]}
                name={'state'}
                getLabel={val => capitalizeFirstLetter(t(val))}
                onChange={event => update(event.target.value as Enum_Ad_State)}
                value={data.state as string}
              />
              {data.isWatched ? (
                <Button onClick={() => removeFromWatchlist().then()}>
                  {ct('stop watching')}
                </Button>
              ) : (
                <Button onClick={() => addToWatchlist().then()}>
                  {ct('watch')}
                </Button>
              )}
              {data.note && <div>{data.note}</div>}
              <Button onClick={() => openAddNote()}>
                {data.note ? ct('edit note') : ct('add note')}
              </Button>
            </div>
          </div>
        )}
      </AdMutationsWrapper>
    </AdContext.Provider>
  );
}
