import { Ad } from 'generated/graphql';
import urljoin from 'url-join';

export const PROFILE = '/profile';
export const PROFILE_EDIT = `${PROFILE}/edit`;

export const BROWSER = '/browser';
export const QUERY = `${BROWSER}/query`;
export const RESULTS = `${BROWSER}/results`;
export const WATCHLIST = `${BROWSER}/watchlist`;
export const getImageUrl = (url: string): string => url;

export const CREATE_ADVERT_BASE = '/create';
export const CREATE_ADVERT = `${CREATE_ADVERT_BASE}/:id?`;
export function getEditAdvertUrl(id: Ad['id']): string {
  return CREATE_ADVERT.replace(':id?', id);
}

export const PUBLISH = '/publish/:id';

export const TERMS_OF_SERVICE = '/terms-of-service';
export const PRIVACY_POLICY = '/privacy-policy';

export function getBackendUrl(relative: string): string {
  const result = urljoin(process.env['REACT_APP_BACKEND_URL'] || '', relative);
  if (relative.startsWith('/') && !result.startsWith('/')) {
    return '/' + result;
  }
  return result;
}
