import React, { useRef } from 'react';
import { merge } from 'public/css';
import { PageContainer } from 'public/components/page-container';
import { AdsBrowserNavbar } from '../components/ads-browser-navbar';
import { Container, useMediaQuery, useTheme } from '@material-ui/core';
import { AdsList } from 'app/ads-browser/components/ads-list';
import { GA_EVENTS } from 'app/root/utils/google-analytics';
import { WatchlistData } from 'app/ads-browser/providers/watchlist-provider';
import { WithClassName } from 'public/props';

export function Watchlist({ className }: WithClassName): JSX.Element {
  const theme = useTheme();
  const isScreenNarrow = useMediaQuery(theme.breakpoints.down('md'));
  const mobileScrollContainer = useRef(null);
  const desktopScrollContainer = useRef(null);

  return (
    <PageContainer
      contentScrollContainerRef={mobileScrollContainer}
      className={merge('results-root', className)}
      scrollElement={isScreenNarrow ? 'CONTENT' : 'NOTHING'}
      navbar={<AdsBrowserNavbar />}
    >
      <div
        className={merge('flex-1', !isScreenNarrow && 'scroll-here')}
        ref={desktopScrollContainer}
      >
        <WatchlistData.Wrapper className={'flex-1'}>
          {({ data, hasMore, loadMore, className }) => (
            <Container>
              <AdsList
                className={className}
                loadMore={() => {
                  GA_EVENTS.query.loadMore();
                  loadMore();
                }}
                hasMore={hasMore}
                data={data}
                getScrollParent={() =>
                  isScreenNarrow
                    ? mobileScrollContainer.current
                    : desktopScrollContainer.current
                }
              />
            </Container>
          )}
        </WatchlistData.Wrapper>
      </div>
    </PageContainer>
  );
}
