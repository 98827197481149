import { makeHandler } from 'branded/analytics/utils/google-analytics';

export const GA_EVENTS = {
  acquisition: {
    visit: makeHandler({ category: 'Acquisition', action: 'Visit' }),
    cookiesAgreement: makeHandler({
      category: 'Acquisition',
      action: 'Cookie agreement'
    })
  },
  navigation: {
    gotoRegister: makeHandler({
      category: 'Navigation',
      action: 'Go to register'
    }),
    goToLogin: makeHandler({ category: 'Navigation', action: 'Go to login' })
  },
  registration: {
    visitPrivacyPolicy: makeHandler({
      category: 'Registration',
      action: 'Visit privacy policy'
    }),
    visitTermsOfUse: makeHandler({
      category: 'Registration',
      action: 'Visit privacy policy'
    }),
    success: makeHandler({ category: 'Registration', action: 'Success' })
  },
  query: {
    query: makeHandler({ category: 'Query', action: 'Update' }),
    loadMore: makeHandler({ category: 'Results', action: 'Load more' })
  }
};
