import React from 'react';
import { Switch } from 'react-router';
import { EditProfile } from './pages/edit-profile';
import { PROFILE_EDIT } from '../root/models/urls';
import { AuthRoute } from 'public/auth/components/auth-route';
import { Redirect } from 'public/routing/components/redirect';
import { WithClassName } from 'public/props';
import { useStylesFill } from 'public/styles';

export function Profile({ className }: WithClassName): JSX.Element {
  const { fill } = useStylesFill();
  return (
    <Switch>
      <AuthRoute
        path={PROFILE_EDIT}
        exact
        className={className}
        contentClassName={className}
      >
        <EditProfile className={className} />
      </AuthRoute>
      <Redirect to={PROFILE_EDIT} />
    </Switch>
  );
}
