import React, { useEffect } from 'react';
import {
  ProviderComponent,
  ProviderComponentProps
} from 'public/components/provider-group';
import { useState } from 'public/hooks/state';
import { useHistory } from 'public/routing/hooks/history';
import { isNull } from 'public/util';
import { GA_EVENTS } from 'app/root/utils/google-analytics';

/**
 * This is a provider because in future it will give some data
 */
export const AnalyticsProvider: ProviderComponent = ({
  children
}: ProviderComponentProps): JSX.Element => {
  const { value: source, set: setSource } = useState<string | undefined>(
    undefined
  );
  const { location, push } = useHistory();
  const { s: currentVisitOrigin } = location.search;

  useEffect(() => {
    if (!isNull(source) && currentVisitOrigin) {
      push(location, currentSearch => {
        // eslint-disable-next-line
        const { s, ...restParams } = currentSearch; // Query { s?: string; }
        return restParams;
      });
      return;
    }
    if (!currentVisitOrigin || typeof currentVisitOrigin !== 'string') {
      return;
    }
    console.log(`Detected that the user has come from ${currentVisitOrigin}`);
    GA_EVENTS.acquisition.visit({ label: currentVisitOrigin });
    setSource(currentVisitOrigin);
  }, [source, currentVisitOrigin, setSource, location, push]);

  return <>{children}</>;
};
