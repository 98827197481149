import { ReactComponent as Frog } from '../graphics/frog.svg';
import { ReactComponent as Label } from '../graphics/label.svg';
import React from 'react';
import { Logo as LogoBase } from 'public/components/logo';

export interface LogoDimensions {
  height: number;
  heightRatio: number;
  margin: number;
}

export const BIG = {
  height: 50,
  heightRatio: 0.88
};

export interface Props {
  wrap?: boolean;
  className?: string;
  height?: number;
  heightRatio?: number;
}

export function Logo({
  wrap = false,
  height = 50,
  heightRatio = 0.8
}: Props): JSX.Element {
  return (
    <LogoBase
      LogoSVG={Frog}
      LabelSVG={Label}
      wrap={wrap}
      height={height}
      heightRatio={heightRatio}
    />
  );
}
