import { useMediaQuery, useTheme } from '@material-ui/core';

export interface ReturnValue {
  isWide: boolean;
  isNarrow: boolean;
}

export function useNavigationVariant(): ReturnValue {
  const theme = useTheme();
  const isWide = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values['md'])
  );
  return {
    isWide,
    isNarrow: !isWide
  };
}
