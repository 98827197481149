import React from 'react';
import {
  ThemeProvider as ThemeProviderBase,
  createTheme
} from '@material-ui/core/styles';
import { ProviderComponentProps } from 'public/components/provider-group';

const materialTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
  },
  palette: {
    primary: { main: 'hsl(18, 72%, 54%)' },
    secondary: { main: 'hsl(94, 34%, 36%)' }
  },
  typography: {
    fontSize: 16,
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    h1: {
      fontSize: '1.6rem',
      lineHeight: 1.5
    },
    h2: {
      fontSize: '1.25rem'
    },
    h3: {
      fontSize: '1rem'
    },
    h4: {
      fontSize: '1rem'
    },
    button: {
      fontWeight: 700,
      textTransform: 'none',
      fontSize: '1rem'
    }
  }
});

export const ThemeProvider = (props: ProviderComponentProps): JSX.Element => (
  <ThemeProviderBase theme={materialTheme} {...props} />
);
