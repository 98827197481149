import React from 'react';
import {
  CompanyDetailsProvider as CompanyDetailsProviderBase,
  LinkProps
} from 'public/providers/company-details-provider';
import { ProviderComponentProps } from 'public/components/provider-group';
import {
  ADDRESS,
  COMPANY_NAME,
  CONTACT_EMAIL,
  DEPLOYMENT_ADDRESS,
  NIP,
  REGON,
  SERVICE_NAME,
  TERMS_OF_SERVICE_URL
} from 'branded/config';
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from 'app/root/models/urls';
import { Link } from 'public/routing/components/link';

export function PrivacyPolicyLink({
  children,
  ...props
}: LinkProps): JSX.Element {
  return (
    <Link to={PRIVACY_POLICY} {...props}>
      {children}
    </Link>
  );
}

export function TermsOfServiceLink({
  children,
  ...props
}: LinkProps): JSX.Element {
  return (
    <Link to={TERMS_OF_SERVICE} {...props}>
      {children}
    </Link>
  );
}

export function CompanyDetailsProvider({
  children
}: ProviderComponentProps): JSX.Element {
  return (
    <CompanyDetailsProviderBase
      contactEmail={CONTACT_EMAIL}
      companyName={COMPANY_NAME}
      nip={NIP}
      regon={REGON}
      address={ADDRESS}
      serviceName={SERVICE_NAME}
      PrivacyPolicyLink={PrivacyPolicyLink}
      TermsOfServiceLink={TermsOfServiceLink}
      deploymentAddress={DEPLOYMENT_ADDRESS}
      termsOfServiceUrl={TERMS_OF_SERVICE_URL}
    >
      {children}
    </CompanyDetailsProviderBase>
  );
}
