import React, { useMemo } from 'react';
import { RESULTS, WATCHLIST } from 'app/root/models/urls';
import { useHistory } from 'public/routing/hooks/history';

export interface Props {
  children: (
    value: number,
    onChange: (event: React.ChangeEvent<any>, value: number) => void
  ) => JSX.Element | null;
}

export function Navigation({ children }: Props): JSX.Element | null {
  const { push, location } = useHistory();

  const value = useMemo(() => {
    switch (location.pathname) {
      case WATCHLIST:
        return 1;
      default:
        return 0;
    }
  }, [location.pathname]);

  function onChange(event: React.ChangeEvent<any>, value: number) {
    if (value === 0) {
      push(RESULTS);
      return;
    }
    if (value === 1) {
      push(WATCHLIST);
      return;
    }
  }

  return children(value, onChange);
}
