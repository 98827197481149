import React from 'react';
import './footer.sass';
import { capitalizeFirstLetter } from 'public/util';
import { useT } from 'public/hooks/translation';
import { Logo } from 'branded/components/logo';
import { Licenses } from 'branded/components/licences-modal';
import { Container, useMediaQuery } from '@material-ui/core';
import { useCompanyDetails } from 'public/providers/company-details-provider';
import { useSimpleDialog } from 'public/dialogs/SimpleDialog';

export function Footer(): JSX.Element {
  const t = useT();
  const { open: openLicenses } = useSimpleDialog(Licenses);
  const { address, nip, regon, contactEmail, companyName } =
    useCompanyDetails();
  const showMiddleLogo = useMediaQuery('(min-width:850px)');
  const enlargeMiddleLogo = useMediaQuery('(min-width:1200px)');

  return (
    <footer className={'footer-root'}>
      <Container>
        {!showMiddleLogo && <Logo height={40} />}
        <div className={'container'}>
          <div className={'address'}>
            <div>{companyName}</div>
            <div>
              {address.map((line, idx) => (
                <span
                  key={idx}
                  className={'new-line-responsive'}
                >{`${line} `}</span>
              ))}
            </div>
            <div>
              <span className={'new-line-responsive'}>{`NIP: ${nip}, `}</span>
              <span className={'new-line-responsive'}>
                {`REGON: ${regon}`}`
              </span>
            </div>
            <div>{contactEmail}</div>
          </div>
          {showMiddleLogo && <Logo height={enlargeMiddleLogo ? 50 : 40} />}
          <div className={'text-right'}>
            {/*{TermsOfServiceLink && (*/}
            {/*  <div>*/}
            {/*    <TermsOfServiceLink>*/}
            {/*      {capitalizeFirstLetter(t('terms of service'))}*/}
            {/*    </TermsOfServiceLink>*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*<div>*/}
            {/*  <PrivacyPolicyLink>*/}
            {/*    {capitalizeFirstLetter(t('privacy policy'))}*/}
            {/*  </PrivacyPolicyLink>*/}
            {/*</div>*/}
            <div className={'link'} onClick={() => openLicenses({})}>
              {capitalizeFirstLetter(t('licenses'))}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
