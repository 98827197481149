import React from 'react';
import ReactDOM from 'react-dom';
import 'branded/styles/index.sass';
import { App } from './app/root/pages/app';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
import { initializeAnalytics } from 'branded/analytics/utils/google-analytics';
// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';
import emailjs from 'emailjs-com';
import { Providers } from './app/root/providers';

smoothscroll.polyfill();

WebFont.load({
  google: {
    families: [
      'Source+Sans+Pro:300,400,700,300italic,400italic,700italic',
      'sans-serif'
    ]
  }
});

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root')
);
// eslint-disable-next-line
emailjs.init(process.env.REACT_APP_EMAILJS_USERID!);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

initializeAnalytics('UA-153229733-1');
